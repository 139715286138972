import React, { Fragment } from 'react';
import {Button,Menu,MenuItem,Tooltip} from '@material-ui/core/';


class SimpleMenu extends React.Component<any,any> {
  state = {
    anchorEl: null,
  };

  handleClick = (event:any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;

    return (
        <Fragment>
        <Tooltip title={this.props.toolTip}>
       
        <Button
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          {this.props.children}
        </Button>
        </Tooltip>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
        {this.props.menuItems.map( (m:any,idx:number) => 
        (<MenuItem 
            key ={idx} 
            
            onClick={ 
              
              (typeof(m.disabled) == "boolean" ? (!m.disabled) : m.disabled(this.props))  ?
              (event) =>   
                { m.onClick(idx); 
                  m.closeOnClick && this.handleClose();}:
               ()=>{
                 const i=1;
               }}>
            {m.name}
          </MenuItem>
          ))}

        </Menu>
        </Fragment>
      
      
    );
  }
}

export default SimpleMenu;
