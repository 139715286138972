import {AppBar,Button,IconButton,Menu,MenuItem,Toolbar,Typography,Tooltip} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import GraphicImage from "../graphic/GraphicImage"

import LoadButton from "../icons/png/load.png"
import ToDoButton from "../icons/svg/ToDo.svg"
import DetailViewButton from "../icons/png/detailView.png"
import GraphicViewButton from "../icons/png/graphicView.png"
import AddNoteButton from "../icons/svg/note.svg"
import ShareButton from "../icons/svg/Share.svg"
import SaveButton from "../icons/png/Save.png"
import ResearchIcon from "../icons/png/research.png"
import CloudButton from "../icons/svg/cloud.svg"
import * as React from 'react'
import {Views} from "../model/Views"
import FactAddModal from "../fact/FactAddModal"

import SimpleMenu from "../popupmenu/popupmenu";

import Options from "../model/Options"

const styles = {
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  root: {
    flexGrow: 1,
  }

  ,iconSize :
    {
      width:"36px",
      height:"36px"
    }
   ,ToolBar :
    {
    
      height:"96px"
    }
    ,	ResearchGrabber: {
      width:"72px",
      height:"72px",
      backgroundImage: `url(${ResearchIcon})`,
      background: "center center no-repeat",
      backgroundSize:"75% 75%",
      marginRight:"20px",
      
    }
     
    ,	ResearchGrabberInput: {
      opacity:0,
      width:"72px",
      height:"72px"
    }       
};

class ToolMenuClass
{
  constructor(public key:number,public viewType : Views, public title: string,public icon:string) {

  }
}

class ToolMenuBar extends React.Component<any,any>{
  
    constructor(props :any) {
		super(props);
        this.state = {
            anchorElement: null
      }

    
    }
  
    checkProtected = (props:any) =>
    {
   
      const o:Options = props.options;
      const {p,...others} = props
      
      return !(o.isProtected)
    }

    fileMenuClick  = (idx:number) =>
    {
      this.props.menuClick(this.fileMenu[idx].name)
    }
    
    fileMenu =
    [
      {onClick:this.fileMenuClick,name:"Save",closeOnClick:true, disabled : this.checkProtected},
      {onClick:this.fileMenuClick,name:"Save As",closeOnClick:true,disabled:false},
      {onClick:this.fileMenuClick,name:"Load",closeOnClick:true,disabled:false},
 
    ];
 
    shareMenuClick  = (idx:number) =>
    {
      this.props.menuClick(this.shareMenu[idx].name)
    }

    shareMenu =
    [
      {onClick:this.shareMenuClick,name:"Share Link",closeOnClick:true, disabled : false},
      {onClick:this.shareMenuClick,name:"Create PDF",closeOnClick:true,disabled:false},

 
    ];


        public render() {

 

          const toolMenuBar : ToolMenuClass[]=[
   
            {key:1,viewType:Views.Note,title:"Add a Research Summary",icon:AddNoteButton} ,
            {key:2,viewType:Views.Graphic,title:"View Fact Diagram",icon:GraphicViewButton},
            {key:3,viewType:Views.Detail,title:"View Fact Details",icon:DetailViewButton}    ,
            {key:4,viewType:Views.ToDo,title:"View To Do List",icon:ToDoButton}   ,   
            {key:5,viewType:Views.Cloud,title:"View Name Cloud",icon:CloudButton}  ,  
     //     {key:6,viewType:Views.Share,title:"Share Research",icon:ShareButton}  ,
      
 
          ];

          const { classes } = this.props;
          const  view = this.props.currentView;

          const menuBar = toolMenuBar.map( (m:ToolMenuClass) =>
          {return ((true) &&
            (
            <Tooltip key={m.key} title={m.title}>
            <Button className={classes.menuButton} color="inherit" aria-label="Menu"  disabled = {view === m.viewType}

                               onClick={this.props.handleViewClick.bind(this,m.viewType)} ><img className = {classes.iconSize} src={m.icon}/>
            </Button> 
             </Tooltip> 
             ))})
 
            return (
            
            <div className={classes.root}>
    
            
                <Toolbar  className = {classes.Toolbar}>
                  <Tooltip title={"Paste your capture Fact Here"}>
                  <span className = {classes.ResearchGrabber} >
                  <input className = {classes.ResearchGrabberInput} onPaste={this.props.handlePaste} type="textarea"></input>
                  </span> 
                  </Tooltip>                               
            
                  <Tooltip title={"Add Fact Here"}>
                  <span>
                  <FactAddModal addFact = {this.props.addFact} userProfile={this.props.userProfile}></FactAddModal>
                  </span> 
                  </Tooltip>
                   {menuBar}

                   <Tooltip title={"Share this Research"}>
                    <SimpleMenu options = {this.props.options} menuItems={this.shareMenu} toolTip="Share Research">
                    <img className = {classes.iconSize} src={ShareButton}/>
                    </SimpleMenu>
   
                  </Tooltip>             
                   
                  <Tooltip title={"Save this Research"}>
                    <SimpleMenu options = {this.props.options} menuItems={this.fileMenu} toolTip="Save and Load Research">
                    <img className = {classes.iconSize} src={SaveButton}/>
                    </SimpleMenu>
   
                  </Tooltip>
                </Toolbar>
        

 
            </div>
          );
        }

    }


export default withStyles(styles)(ToolMenuBar);