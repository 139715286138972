// A word cloud react component built with d3-cloud. https://github.com/Yoctol/react-d3-cloud

import WordCloud from 'react-d3-cloud';
import React, { Component, Fragment } from 'react';
import {ResearchNode,ResearchType,ResearchItem} from '../model/ResearchNode'
import {Paper} from '@material-ui/core'
import {withSize} from 'react-sizeme'
import ReactDOM from 'react-dom'
import {DetailModalPanel} from '../detail/DetailModal'
import {findFact} from "../utility/ResearchUtil"

class tag 
{
    text:string = ""
    value:number = 0
    factArray :string[] = []
}



const NameRegEx = /WITNESS|NAME|SPONSOR/gmi
const CenusNameRegEx = /NAME/gmi

class NameCloud extends React.Component<any,any>{

    facts : ResearchNode[]|void[]|undefined = [];
    name : string ="";

    constructor(props:any)
    {
        super(props);
        this.state = {
            detailView:false}

        
    }

    refCallback = (ref :any) =>

    //TODO This shoud get the dom SVG element 
    {
        if (ref) 
        {
       const domSVG =  ReactDOM.findDOMNode(ref.wordCloud.childNodes[0])
        }
    }

    onWordClick = (word:tag)  =>
    {

        let facts:ResearchNode[] =[]
        word.factArray.forEach((id) => { 
            const f= findFact(this.props.research,id)
            if (f) {facts.push(f)}
            });

        if (facts.length>0)
        {
            this.facts = facts?facts:[]
            this.name = word.text;
            this.setState({detailView:true})
        }
    }

    onCloseFactDetailView = () =>
    {
        this.setState({detailView:false})
    }

    render () 
    {

    const { width, height } = this.props.size
 
    const fontSizeMapper = (word:tag) => (word.value) * 10;
    // const rotate = (word:tag) => word.value % 360;
    let names : tag[] = [];
    

    const research = this.props.research.filter((r:ResearchNode) => ((r.nodeType != ResearchType.Other) && !r.isRemoved ));

    research.forEach((f:ResearchNode) => {
        let researchNames : string[] = [];     
        let fact : any
        for ( fact in f.research)
        {
            if ((fact === "CensusDetail") && (Array.isArray(f.research[fact].value)))
            {
                f.research[fact].value.forEach( (v:any) =>
                    {
                      let f :string
                      let surname:string="";
                      let forename:string="";
                      for  (  f in v.value)
                      {
                        const value = v.value[f].value;  
                        if (f.search(/Forename/gmi) >=0) 
                        {
                            forename = value;
                            researchNames.push(value);
                        }
                        else if (f.search(/Surnam/gmi) >=0) 
                        {
                            surname = value;
                            researchNames.push(value) 
                        }
                        else if ( (f.search(CenusNameRegEx) >= 0))
                        {
                            researchNames.push(value);
                            
                        }
                      }
                      if ((surname.length >0) && (forename.length >0))
                      {
                      researchNames.push( forename + " " + surname );
                      }
                    })
            }
            else if ((f.research[fact].type === "name")  || (fact.search(NameRegEx) >= 0))
            {
                if ((f.research[fact].value.search (/n\/r/gmi) < 0) && (f.research[fact].value.length >0))
                {
                researchNames.push(f.research[fact].value)
                }
            }

        }
   
        researchNames.forEach((n:string) => {
            const nameFound = names.find((nt:tag) => (nt.text == n.trim().toUpperCase()) )
            if (nameFound) 
            {
                nameFound.value++;
                if (!nameFound.factArray.find( s=> s===f.id))
                {
                nameFound.factArray.push(f.id);
                }
            }
            else
            {
                names.push({text:n.trim().toUpperCase(),value:1,factArray:[f.id]})
            }
        })
    });
    

 return ( 
    <Paper
    elevation={0} style={{ padding: 0, margin: 0, backgroundColor: "#fafafa" }}>
     
           <DetailModalPanel open={this.state.detailView}
                            detail = {this.facts}
                            onClose={this.onCloseFactDetailView}
                            title = {"Facts containing the name " + this.name } >
             }>

             </DetailModalPanel>

    <WordCloud
        width ={width?width:700}
        height = {height ? height:600}
        data={names}
        fontSizeMapper={fontSizeMapper} 

        ref={this.refCallback} 

        onWordClick={this.onWordClick}
    />
    </Paper>
    )
 }

}

 const withSizeHOC = withSize({  })
 export default withSizeHOC(NameCloud)