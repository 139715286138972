import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {IconButton,Button , Dialog ,DialogActions,DialogTitle,DialogContent,DialogContentText, Paper,TextField, Checkbox,FormControl,InputLabel,Select,Menu,MenuItem} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import {ResearchNode} from '../model/ResearchNode';
import AddFactButton from "../icons/svg/add.svg"
import FactForm from '../fact/FactForm'


const styles = {
 
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },


  iconSize :
    {
      width:"36px",
      height:"36px"
    },


};

/**
 * Standard Component for Modal Form and Button for adding Facts
 * 
 * AddFormDialogWrapped - Add for only handel open close
 * AddFactModal - With button
 * 
 * @param {Function} addFact  called when the submit button is pressed
 * @param {class}  optional wrapper for style
 * @param {Boolean} open   required for AddFormDialogWrapped 
 * @param {Function} onCloserequired for AddFormDialogWrapped 
 * @returns 
 */


class SimpleDialog extends  React.Component<any,any>{


  constructor(props:any)
  {
      super(props);

      this.props.addFact.bind(this);
    
      this.state = {
          linkPicker:false
      }
  }

  handleClose = () => {
    this.props.onClose(this.props.selectedValue);
  };

  handleListItemClick = (value:any) => {
    this.props.onClose(value);
  };

  submit = (fact:ResearchNode) => {
   
    this.props.addFact(fact)
    this.setState({changed:true});
    
  }


  render() {
   
    const { classes, onClose, selectedValue,  ...other} = this.props;
    const form = new ResearchNode("","",0)
    const sliderValue = this.state.sliderValue
    
    return (
      <Dialog open={true} onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other}>

        <DialogTitle id="draggable-dialog-title" >
        Enter Fact Details
        </DialogTitle  >
          <DialogContent>
            <DialogContentText>
            <Paper elevation={2}  style={{ margin: 16, padding: 16 }}>
            <FactForm onSubmit={this.submit}  internal = {true}  userProfile={this.props.userProfile}>
            </FactForm>
                </Paper>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
                <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                    <CloseIcon />
                </IconButton>
            </DialogActions>
      </Dialog>
    );
  }
}



export const AddFormDialogWrapped = withStyles(styles)(SimpleDialog);

class AddFactModal  extends React.Component<any,any>{

    constructor(props:any)
    {
        super(props);
        this.state = {
          open: false

        };
    }


  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({  open: false });
  };

  render() {
    
    const { classes } = this.props;
    return (
      <div>


        <Button className={classes.menuButton} color="inherit" aria-label="Menu"  
            onClick={this.handleClickOpen} ><img className = {classes.iconSize} src={AddFactButton }/>
        </Button> 

        <AddFormDialogWrapped
          open={this.state.open}
          onClose={this.handleClose}
         
          {...this.props}
        />
      </div>
    );
  }
}

export default withStyles(styles)(AddFactModal);
