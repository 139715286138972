import {AppBar,Button,IconButton,Menu,MenuItem,Toolbar,Typography,Link} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'


import {Mail,ExitToApp,Help,AccountCircle,Menu as MenuIcon}  from '@material-ui/icons'
import * as React from 'react'


const styles = {
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  root: {
    flexGrow: 1,
  }
};



class AppMenuBar extends React.Component<any,any>{
  
    constructor(props :any) {
		super(props);
        this.state = {
            anchorElement: null
      }
      this.handleClick= this.handleClick.bind(this);
      this.handleClose= this.handleClose.bind(this);     
    }

    public handleClick = (event:any) => {
        this.setState({ anchorElement: event.currentTarget });
      };
    
      public handleClose = () => {
        this.setState({ anchorElement: null });
      }


        public render() {
          const { classes } = this.props;
          const anchorElement = this.state.anchorElement
          return (
            <div className={classes.root}>
              <AppBar position="static">
                <Toolbar>
                <Button className={classes.menuButton} color="inherit" aria-label="Menu"
                                   aria-owns={anchorElement ? 'simple-menu' : undefined}
                                   aria-haspopup="true"
                                   onClick={this.handleClick}>
                    <MenuIcon/>
                </Button>                  
                
                
                  <Typography color="inherit" className={classes.grow} variant="h5" component={'span'}>
                    Family Tree Researcher 
                    <Typography component={'span'} variant = "subtitle1">{this.props.version}</Typography> 
                  </Typography>
           
             

                {this.props.authUser && <Button color="inherit"><AccountCircle/>{this.props.userProfile.username}</Button> }
                </Toolbar>
              </AppBar>

              <Menu
                  id="simple-menu"
                  anchorEl={anchorElement}
                  open={Boolean(anchorElement)}
                  onClose={this.handleClose}
                >
                  <MenuItem onClick={this.handleClose} >
                    <Link color="inherit" href={"http://www.matthatwaspat.com/wp-content/uploads/2019/05/PTWM-Researcher.pdf"}  target={'_blank'}>
                     <Help/>Help
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={this.handleClose}>
                    <Link color="inherit"  href={"http://www.matthatwaspat.com/mtwp-researcher-contact"}  target={'_blank'}>
                      <Mail/>Contact Us
                    </Link>
                  </MenuItem>
                  {this.props.authUser && 
                  <MenuItem  onClick={() => {this.handleClose();this.props.handleLogout();}}>
                    <ExitToApp/>Logout
                    </MenuItem>
                  }
                </Menu>
            </div>
          );
        }

    }


export default withStyles(styles)(AppMenuBar);