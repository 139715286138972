import {CheckCircle,Warning,Error,Info} from '@material-ui/icons'
 
const MessageIcons =
{
    
        success: CheckCircle,
        warning: Warning,
        error: Error,
        info: Info,
     
}


export const Messages:any = 
{
    FileLoaded: {message:"Research Project Loaded",status:true,id:"01",type:"succes",icon:CheckCircle},
    FileLoadError:  {message:"Can not load that Project",status:true,id:"02",type:"succes",icon:Error},
    FactAdded:  {message:"New Fact Added ",status:true,id:"02",type:"succes",icon:CheckCircle},
    FactSAdded:  {message:"New Facts Added ",status:true,id:"02",type:"succes",icon:CheckCircle},    
    PasteError:  {message:"Can not Paste this Fact",status:true,id:"02",type:"succes",icon:Error},
    ScrollDownWarning:  {message:"Facts at bottom of the Page",status:true,id:"02",type:"warning",icon:Warning},
    ScrollUpWarning:  {message:"Facts at top of the Page",status:true,id:"02",type:"succes",icon:Warning},
    ZoomInWarning:  {message:"Can not zoom further without losing Facts from the Page",status:true,id:"02",type:"succes",icon:Warning},
    ZoomInLimit:  {message:"Can not zoom in any further then 10%",status:true,id:"02",type:"succes",icon:Warning},
    SignIn:  {message:"Sign in sucessfull",status:true,id:"02",type:"succes",icon:MessageIcons.info},
    SignOutOk:  {message:"Sign out sucessfull",status:true,id:"02",type:"succes",icon:MessageIcons.info},
    SignOutBad:  {message:"There was a problem signing out ",status:true,id:"02",type:"error",icon:MessageIcons.error},    
}

export default Messages