import shortid from "shortid";

export class ToDo
{
    public note:string = ""
    public nodeId:string = ""
    public id:string = ""
    
}

export class ToDoFormData extends ToDo
{
    
}

export default ToDo