import * as React from 'react'
import { Paper } from "@material-ui/core";

import { Image,Page, Text, View, Document, StyleSheet, Link} from '@react-pdf/renderer';
import { ResearchItem ,ResearchNode,SourceType} from '../model/ResearchNode';
import {ToDo,ToDoFormData} from "../todo/ToDo"
import {Note} from "../note/Note"
import ResearchIcon from "../icons/png/research.png"
import styled from '@react-pdf/styled-components';
import PDFViewCensus from "./PDFViewCensus"
import PDFViewFact from "./PDFViewFact"
import {PDFViewFactSummary} from "./PDFViewFactHeader"

const Fragment = React.Fragment;
import{Body,Heading,HeadingText,Subtitle,Paragraph,SectionHeading,Logo} from "./PDFViewStyles"

import {PageDetail,PageA4P,PageA5L} from "../page/Page"
import {findFact} from '../utility/ResearchUtil'

class PDFView extends React.Component <any,any>{

    
    constructor(props:any)
    {
      super(props)
     
  
    }  
  
  
    render() {
     
      const note:Note = this.props.note;
      const research = this.props.research
        .filter((e:ResearchNode) => e.isRemoved != true)
        .sort((a:ResearchNode,b:ResearchNode) => {return a.displayNumber > b.displayNumber? 1:-1});
      const {todo,base64GraphicView} = this.props;  
 
      const todos = todo.filter((e:ToDo) => {
        const fact = findFact(this.props.research,e.nodeId)
        return (fact?!fact.isRemoved:true)
      })
      
      const todoSortedByNode = todos.sort((a:ToDo,b:ToDo)=> {
        return a.nodeId>b.nodeId? 1:-1})
      
      const gx  = PageA4P.x;
      const gy = PageA4P.y

      return (
  
        <Document>
        <Body wrap>
            
        <Heading fixed >
                <Logo   source={ResearchIcon} fixed ></Logo>
                <HeadingText fixed  >{note.name}</HeadingText>
            </Heading>
            
            <Subtitle >
                {note.description}
            </Subtitle>
            <Paragraph  >
                {note.summary}
            </Paragraph>


           
            <Image src={base64GraphicView} 
            ></Image>
            
     

 
        </Body>
        
        <Body>
          <SectionHeading fixed >To Do</SectionHeading>  
           {todoSortedByNode.map( (t:ToDo,idx:number) =>
            {
            const fact=findFact(research,t.nodeId)
            return (<Fragment key={idx}>
            <Paragraph>
          
        
            {t.nodeId && t.nodeId != "" && (
              <PDFViewFactSummary fontSize={10} fact={fact}/>)
           }
           <Paragraph>{t.note}</Paragraph>
    </Paragraph>
           </Fragment>)})}
            
        </Body>
    
       
        <Body>
        <SectionHeading fixed>Fact Information</SectionHeading>
        {research.map( (fact:ResearchNode) => 
       
          (
            <Fragment key={fact.id}>
         
              {fact.type === "Census" && 
                <PDFViewCensus fact={fact}></PDFViewCensus>
              }
              {fact.type != "Census" && 
                <PDFViewFact fact={fact}></PDFViewFact>
              }
       


           </Fragment>
         
          ))}     
        </Body>
           

       
        </Document>
      )
    }
  }
  
  export default PDFView 
