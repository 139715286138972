
import  birth from '../icons/svg/birth.svg';
import  wedding from '../icons/svg/wedding.svg';
import  death from '../icons/svg/death.svg'; 
import census from '../icons/svg/census.svg';
import other from '../icons/svg/other.svg'
import link from '../icons/svg/externallink.svg'

import shortid from 'shortid'

export class Coordinates
    {
    constructor (public x:number,public y:number )
    {}
    }

export class Arrow
{
    public id:string;

    constructor(public start :Coordinates,public end : Coordinates,public fromNode : string,public toNode : string)

    {
        this.id=shortid.generate();
    }
    
}


export class DisplayNode 
{   
    static setImage(node:DisplayNode)
    {
        switch(node.nodeType) { 
            case "Birth": { 
               node.image = birth;
               break; 
               } ;
            case "Wedding": { 
                node.image = wedding;
                break; 
            } ;   
            case "Death": { 
                node.image = death;
                break; 
            } ; 
            case "Census": { 
                node.image = census;
                break; 
            } ; 
            case "Link": { 
                node.image = link;
                break; 
            } ;         
            default:
            {
                node.image =  other;
                break;  
            }                              
            }   
    }

    public id:string;
    public level : string;
    public nodeType: string;
    public name :string;
    public position : Coordinates;
    public width:number;
    public height:number;
    public isSelected:boolean;
    public isSelectedRow:boolean;

    public label :string;
    color:string;
    shape: string;
    image: string = other;

   constructor(type:string,name:string,i:string)
   {
    this.id=i;
    this.position={x:0,y:0};
    
    this.width=100;
    this.height=100;
    this.isSelected =  false;
    this.isSelectedRow =  false;
    this.level = i;
    this.nodeType = type;
    this.name = name;
    this.label = name;
    this.color =  "#e04141"
    this.shape ="image"
    DisplayNode.setImage(this);

   
    }
}