
import {PageDetail,PageA4P} from "../page/Page"

export const HostURL = "https://research.matthatwaspat.com/";

export enum YearOrderingType 
{
    Strict,
    Loose
}
interface IOptions
{
    startingHalfDecade:number
    startingYear : number;
    zoom : number
    pageSize : PageDetail
    yearOrdering : YearOrderingType
}

export class Options implements IOptions
{
    startingHalfDecade:number
    startingYear : number;
    zoom : number
    factSize : number;
    pageSize : PageDetail
    yearOrdering : YearOrderingType
    nextDisplayNumber : number =0;
    isProtected = false;
    constructor()
    {
        this.startingHalfDecade=0;
        this.startingYear  = 0;
        this.zoom =1;
        this.factSize = 100; 
        this.pageSize = PageA4P;
        this.yearOrdering = YearOrderingType.Loose;
       
    }
}
export default Options
