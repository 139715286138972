class FileInformation
{
constructor(public fileId: string, public name:string,public project:string,public numberOfFacts:number
        ,public created:number,public lastsaved:number,public version:number,public description:string
        ,public isProtected:boolean,public year:number)
{

}
}

export {FileInformation}