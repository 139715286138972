import React from 'react';
import {Component,Fragment} from 'react';
import {Button,Dialog,DialogActions,DialogContent,DialogTitle,CircularProgress,Typography} from '@material-ui/core/';
import { withFirebase } from '../firebase';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table'
import {DateTimeDisplay} from './FileDateTime'
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import SortArrow from '@material-ui/icons/Sort';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Add from '@material-ui/icons/Add';
import {Clear,Delete, ThreeDRotationSharp} from '@material-ui/icons/';

import FileControl from './FileControl'
import {ConfirmDialog} from '../utility/Confirm'
import {FileInformation } from './File';

const styles = {


  modalLoad:
  {
   width: "80%",
   maxWidth : "1400px"
  },

 
};



class FileDialogBase extends Component<any,any> {

  /*
  Returns a Research File or File Info depending on prop passed (onRowSelected,onFileLoad)

  */
  public fileList : FileInformation[];
  fileDetail : FileInformation;
  tableData = {id:0}

  constructor(props:any)
  {
    super(props)
    this.state = {
      open: props.open,
      scroll: 'paper',
      fileList : false,
      confirm : false,
      protectedRemovalOk : false,
      protectedRemovalConfirm : false
    };
    this.fileList=[];
    this.fileDetail = new FileInformation("","","",0,0,0,0,"",false,0)
    }

  handleClickOpen = () => () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.props.handleClose(false);
    this.setState({ open: false });
  };


  componentWillReceiveProps(nextProps:any) {
/* If closed from modal view need to update with new prop value */

  if (nextProps.open!= this.state.open)
  {
    this.setState({open:nextProps.open})
  }
  }


  componentDidMount ()
  {

    const self=this;

    this.props.firebase.myDocs(this.props.firebase.auth.currentUser.uid)
      .once('value').then(function(snapshot:any) {
        snapshot.forEach(function(childSnapshot:any) {

          var key = childSnapshot.key;
          var childData = childSnapshot.val();
          self.fileList.push({fileId:key,...childData});
          
      });
      self.fileList.forEach( f => { f["isProtected"]=f.isProtected?f.isProtected:false
        
      }); 

    const newDates = self.fileList.map((f) => f.lastsaved);

    const newfileList = self.fileList.sort((a,b)=> {
      const aDate = new Date(a.lastsaved);
      const bDate = new Date (b.lastsaved);
      return (b.lastsaved - a.lastsaved);
    })

    self.fileList = newfileList;

    self.setState({fileList:true,confirm:false})
      
    });
  }

  onClickLoad = (event:any, rowData:any)=>
  {

    const self = this;
    const fileId = rowData.fileId
    if (this.props.onRowSelected)
    {
      this.props.onRowSelected(rowData)
    }
    else if (this.props.onFileLoad)
    {
    this.props.firebase.document(this.props.firebase.auth.currentUser.uid,fileId)
    .once('value').then(function(snapshot:any) {
     
      self.props.onFileLoad(snapshot)
     
        
    });
  }
  }

  confirmProtectedRemoval = () =>
  {
    this.setState({protectedRemovalConfirm : true});
  }

  confirmProtectedRemovalConfirmed = (confirm:boolean) =>
  {
  
    const newState = (this.state.protectedRemovalOk ?(!confirm):(confirm))
    this.setState({protectedRemovalOk : newState ,protectedRemovalConfirm : false});
  }  

  deleteFile = (event:any, rowData:any)=>
  {
    const {tableData, ...fileDetail} = rowData;
    this.fileDetail = fileDetail;
    this.tableData = tableData;
    this.setState({confirm:true})
  }

  deleteFileConfirmed = (confirm:boolean) =>
  {
    if (confirm)
      {
      FileControl.deleteExisting(this.props.firebase, this.props.firebase.auth.currentUser.uid, this.fileDetail.fileId)
      this.fileList.splice(this.tableData.id,1)
      }
    this.setState({confirm:false})
  }


  render() {
    
    const small = this.props.small;

    return (
      <div>


     

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          scroll={'paper'}
          aria-labelledby="scroll-dialog-title"
          className = {this.props.classes.modalLoad}
          maxWidth ={'xl'}
        >
          <DialogTitle id="scroll-dialog-title">Saved Researches</DialogTitle>
          <DialogContent>
          {!this.state.fileList && <CircularProgress  />}
       
          {this.state.confirm &&
          <ConfirmDialog title = {"Confirm Research Deletion"} 
            description ={<Fragment>
                            <Typography variant='subtitle1'>Deletion of Research Name: {this.fileDetail.name}</Typography>
                             <Typography variant='subtitle2'>Project : {this.fileDetail.project}</Typography>
                             <Typography variant='subtitle2'>Version: : {this.fileDetail.version}</Typography>
                             {this.fileDetail.isProtected &&
                             <Typography color ={'error'} variant='subtitle2'>This research is marked as Protected !</Typography>               
                             }
                            </Fragment>}
            onClose={this.deleteFileConfirmed} />
          } 

          {this.state.protectedRemovalConfirm &&
          <ConfirmDialog title = {"Deletion of Protected Research"} 
            description ={<Fragment>
                            <Typography variant='subtitle1'>This will {this.state.protectedRemovalOk?'prohibit':'allow'} the deletion of Protect Research, Please confirm you {this.state.protectedRemovalOk?'no longer require':'require'} require this feature</Typography>

                            </Fragment>}
            onClose={this.confirmProtectedRemovalConfirmed } />
          }

          {this.state.fileList &&
            <DialogContent>
              
          <MaterialTable
              icons={{ 
                Check: Check,
                DetailPanel: ChevronRight,
                Export: SaveAlt,
                Filter: FilterList,
                FirstPage: FirstPage,
                LastPage: LastPage,
                NextPage: ChevronRight,
                PreviousPage: ChevronLeft,
                Search: Search,
                ThirdStateCheck: Remove,
                ResetSearch:Clear
              }}


              columns={[ { title: 'File Id', field: 'fileId', hidden:true },  
              {title: 'Name', field: 'name' ,searchable:true,filtering:true,sorting:true},
              {title:'Project',field: 'project',searchable:true,filtering:true,sorting:true},
              {title:"Version",field:'version',searchable:true,filtering:false,sorting:true},
              {title:'Facts',field:'numberOfFacts' ,type:'numeric',filtering:false},
              {title:'Protected',field:'isProtected' ,type:'boolean',filtering:true,sorting:true},
              {title:'Modified',field:'lastsaved',type:'datetime',filtering:false
                ,render: (dataRow:any) => {return (<DateTimeDisplay dt={dataRow.lastsaved}></DateTimeDisplay>)}},
              {title:'Created',field:'created' ,type:'datetime' ,filtering:false
                ,render: (dataRow:any) => {return (<DateTimeDisplay dt={dataRow.created}></DateTimeDisplay>)}}
            ]}
              
              data={this.fileList}
    
              title= {this.props.title || "List of Research Projects that have been saved "}
              options={{
                toolbar: true,
                paging: false,
                filtering: true,
                actionsColumnIndex: -1
              }}
              onRowClick={this.onClickLoad}

              actions = {small ? [] :[

                
                {
                  icon: Delete,
                  tooltip: `${this.state.protectedRemovalOk?"Prohibit":"Allow"} Removal of Protected Research`,
                  onClick: this.confirmProtectedRemoval,
                  isFreeAction: true, 
                  iconProps :{color:'error'}   
                  },


                  (rowData:any)  => ({
                    icon: Delete,
                    tooltip: 'Remove Research',
                    onClick: (event:any, rowData:any) =>{this.deleteFile(event,rowData)},
                    disabled: !(this.state.protectedRemovalOk || (!(rowData.isProtected ))),
                    iconProps :{color: (this.state.protectedRemovalOk && rowData.isProtected)?'error':'inherit'} 
                  })                  
              ]}            

              />

            </DialogContent>
          }
         
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>

          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const FileDialog = withStyles(styles)(FileDialogBase);

export default withFirebase(FileDialog);
