import React, { Component } from 'react';
import './App.css';

import StatusBar from './menu/StatusBar';

import AppMenuBar from './menu/AppMenuBar';
import {ResearchNode, ResearchItem} from "./model/ResearchNode";
import GraphicView from "./graphic/GraphicView"
import ToolMenuBar from "./menu/ToolMenuBar"
import {Views} from "./model/Views"
import DetailView from "./detail/DetailView"
import NoteAddView from "./note/NoteAddView"
import {Coordinates,Arrow,DisplayNode} from "./model/DisplayNode"
import ToDoListView from './todo/ToDoView';
import {ToDo,ToDoFormData} from "./todo/ToDo"
import {Note} from "./note/Note"
import {PDFViewer} from '@react-pdf/renderer';
import PDFView from "./pdf/PDFView"

import SignUpPage from "./signon/registerForm"

import {withFirebase } from './firebase';

import FileDialog from './filesaveload'
import FileControl from './filesaveload/FileControl'

import { version } from '../package.json'

import {PageDetail,PageA4P,PageA5L} from './page/Page'

import {Options,YearOrderingType,HostURL} from "./model/Options"
import StatusMessage from "./model/Status"
import Messages from "./messages/Messages"

import {yearSort,rebuild,findFact} from './utility/ResearchUtil'
import ConfirmDialog from './utility/Confirm'
import shortid from 'shortid'
import {Typography } from '@material-ui/core'

import NameCloud from './cloud/CloudView'

import ShareLinkModal from './link/ShareLinkModal'

class App extends React.Component<any,any>{

   
  public status:StatusMessage = new StatusMessage();

  currentView =  Views.Graphic;
  rowSelected : number;
  public options : Options;
  public nodeSelected :boolean
  public nodeSelectedId : string;
  public toDoSelected : number;
  public arrows :Arrow[];
  public research : ResearchNode[]; 
  public todo :ToDo[];
  public toDoFormData :ToDoFormData;
  public note:Note;

  public pageSize : PageDetail = PageA4P;

  public arrowStartCoordinates : Coordinates

  public stageRef :any;
  public base64GraphicView : string = "";
  // public waitingforGraphic : boolean;

  fileReference : string = "";
  fileSaved : boolean = false;


  dropped : ResearchNode = new ResearchNode("","");
  droppedOn : ResearchNode = new ResearchNode("","");
  droppedOnConfirm :boolean =false;

  public userProfile : any;
  public urlParams : URLSearchParams;
  public urlUser : string|null = null;
  public urlResearch: string|null = null;

  public graphicRendered : boolean = false;

  constructor(props:any)
  {
    super(props)
    this.resetUser();
    
    this.handlePaste=this.handlePaste.bind(this);
    this.handleViewClick=this.handleViewClick.bind(this);  

    // this.onDragEnd = this.onDragEnd.bind(this);

    this.options = new Options();
    this.research = []
    this.arrows = []
    this.todo = []
    this.currentView =  Views.Graphic;
    this.rowSelected = -1;
    this.toDoSelected = -1;
    this.toDoFormData= new ToDoFormData();
    this.nodeSelected = false
    this.nodeSelectedId = "";
    this.arrowStartCoordinates = {x:0,y:0}
    this.note = new Note("Research ","","","","0")
    this.state= 
      {
      arrows :[],
      research:[],
      todo:[],
      count:this.research.length,
      view:this.currentView,
      todDoForm : this.toDoFormData,
      graphicRendered: false,
      authUser: null,
      fileDialog: true,
      linkDialog : false
   
      }

      /* Check if the URL has a request for a particuliar research to be loaded*/
     this.urlParams = new URLSearchParams(window.location.search)
     if (this.urlParams)
     {
      this.urlUser = this.urlParams.get('user');
      this.urlResearch = this.urlParams.get('research');
     }
  }




  rebuildAll = () =>
  {
    rebuild(this.research,this.arrows,this.todo,this.options);
  }

  resetUser = () =>
  {

    this.stageRef = {}
    this.base64GraphicView = "";
    this.fileReference =""
    this.fileSaved = false;
    this.options = new Options();
    this.research = []
    this.arrows = []
    this.todo = []
    this.currentView =  Views.Graphic;
    this.rowSelected = -1;
    this.toDoSelected = -1;
    this.toDoFormData= new ToDoFormData();
    this.nodeSelected = false
    this.nodeSelectedId = "";
    this.arrowStartCoordinates = {x:0,y:0}
    this.note = new Note("Research ","","","","0")
    this.graphicRendered =false;
   
   /* this.state= 
      {
      arrows :[],
      research:[],
      todo:[],
      count:this.research.length,
      view:this.currentView,
      todDoForm : this.toDoFormData,
      graphicRendered: false,
      authUser: null,
      fileDialog: true
   
      }
*/

      this.userProfile ={};

  }


  ClearToDoForm = (e:number) =>
  {
    this.toDoSelected = -1;
    this.toDoFormData = new ToDoFormData()
    this.setState({todDoFormData : this.toDoFormData});
  }

  UpdateToDoList= (toDo:ToDoFormData) =>
  {
    /*
    const fact = findFact(this.research,toDo.nodeId.toString())
    toDo.nodeId = fact?Number(fact.id):toDo.nodeId;
    */

    if (this.toDoSelected >= 0)
    {

      this.todo[this.toDoSelected]=toDo
      this.toDoSelected=-1;
      this.toDoFormData= new ToDoFormData()
      this.setState({todDoFormData : this.toDoFormData});
    }
    else{
      toDo.id = shortid.generate();
      this.todo.push({...toDo});
      toDo.id =""
      this.setState({todo:this.todo.slice()})
    }
  }
 

  ToDoSelected = (e:number) =>
  {
    this.toDoSelected = e;
    this.toDoFormData = {...this.todo[e]}
    this.setState({todDoFormData : this.toDoFormData});
  }

  ToDoRemove= (e:any) =>
  {
    
    this.todo.splice(e,1);
    if ((this.toDoSelected == e) )
      {
        this.toDoSelected =-1
        this.toDoFormData= new ToDoFormData()
      }
    this.setState({todo:this.todo.slice()});      
    
  }

  parsePaste(r:any)
  {
    let node = new ResearchNode(r.Type.value,r.Name.value,r.Year.value,r.Location.value );
    node.research = {...r};
    node.sourceScrape = r.sourceScrape;
    if (r.Source) 
      {node.source = new ResearchItem("Source",r.Source.value,0)};
    node.confidence =100;
   
    return node;
  }

  handlePaste (e:any)
  {
    e.preventDefault()
    try
    {
    

      //TODO For IE  this  need window.clipboardData.getData('Text')
    let r = JSON.parse(e.clipboardData.getData('text/plain'));

    if (typeof(r=== "object"))
      {
        const isArray =  Array.isArray(r)
        let j= isArray?r.length:1;
        for (var i=0;i<j;i++)
        {
        
          let node = (isArray) ? this.parsePaste(r[i]): this.parsePaste(r)
          this.options = {...this.options,...this.calculateForFirstYear(node.year)}
          node.position.y = this.calculateYForYear(node.year);  
          node.displayNumber = this.options.nextDisplayNumber;
          this.options.nextDisplayNumber++;

          this.research.push(node);
        }

      this.status= isArray ? {...Messages.FactsAdded} : {...Messages.FactAdded} 
      let nodesCopy = this.research.slice();
      this.setState({count:this.research.length, research:nodesCopy});
      }
    }
    catch(e)
    {
      console.log(e)
      this.statusUpdate(Messages.PasteError)

    }   
  }


 
  calculateForFirstYear = (year:number|string|undefined) =>
  {
    let firstYear = {};

    if ((this.options.startingHalfDecade=== 0) && (Number(year)))
    {
      const y = Number(year)
      if (y)
      {
        if (this.options.yearOrdering == YearOrderingType.Strict)
          {
        
          const startingYear =  Math.ceil((y|| 0) - (this.options.pageSize.years/2));
          const startingHalfDecade= Math.ceil(startingYear / 5) * 5
          firstYear = {startingYear,startingHalfDecade}
          }
        else if (this.options.yearOrdering == YearOrderingType.Loose)
          {
            const startingYear =  (y|| 0) 
            const startingHalfDecade= Math.ceil(startingYear / 5) * 5
            firstYear = {startingYear,startingHalfDecade}
          }     
      }
    }
    return firstYear;
  }


  
  calculateYForYear = (year:number|string|undefined):number =>
  /* Returns the Y Cordinate for a given year, or max/min if it is not displayable in Strict Mode

  In loose mode it finds the cordinates of the closest year
  */

  {
    const y = Number(year)
    if (y)
    {
    const bottom = this.options.pageSize.y - this.options.factSize -20;
    const top = 0;

    // const coOrd=  (y - this.options.startingHalfDecade) * this.options.factSize;
    if (this.options.yearOrdering === YearOrderingType.Strict)
      {
      const coOrd = 1+(Math.round((y - this.options.startingHalfDecade) * 100 * this.options.zoom));
      if (coOrd < top)
        {
          return 1
        }
      else if (coOrd > bottom)
        {
          return bottom
        }
      else
        {
          return coOrd
        }
      }
      else if (this.options.yearOrdering === YearOrderingType.Loose)    
      {
        const facts = this.research.sort(yearSort);
        const closestF = facts.find((f) => {return ((f.year?f.year:0) > y)})
        if (closestF)
        {
          return closestF.position.y;
        }
        else if (facts && facts.length >0)
        {
          return facts[facts.length-1].position.y
        }
        else 
        {
          return 1
        }
      }
    }
      else
      {
        return 1
      }
    
      return 1
  }


  addFact = (values:ResearchNode) =>

    {

      let f  = new ResearchNode(values.type,values.name,0);

      f= {...f,...values}
      // f.research = {description:{title:"Description",value:values.description}}

      
      f.sourceScrape.type = "Internal";
      f.sourceScrape.value = this.userProfile.username;
    

      // const source = new ResearchItem("Source",values.source,0)
      // f.source  = source;

      this.options = {...this.options,...this.calculateForFirstYear(f.year)}
      f.position.y = this.calculateYForYear(f.year);


      f.displayNumber = this.options.nextDisplayNumber;
      this.options.nextDisplayNumber++;

      this.research.push(f);
      let nodesCopy = this.research.slice();

      this.status= {...Messages.FactAdded}

   

      this.setState({count:this.research.length, research:nodesCopy});
    }  


   // Detail View 
    
    updateFact = (newValues:ResearchNode) =>
    {
      const id =  newValues.id;
      let factToUpdateId = this.research.findIndex((f) => {return (f.id === id)}) 
      this.research[factToUpdateId] = newValues;
      if (factToUpdateId >= 0)
      {
        let nodesCopy = this.research.slice();
        this.setState({count:this.research.length, research:nodesCopy});
      }

      /*
      this.research[factToUpdateId] = {...this.research[factToUpdateId],...newValues};
      if (factToUpdateId >= 0)
        {
 
        if (this.research[factToUpdateId].sourceScrape.type === "Internal")
        {
          this.research[factToUpdateId].source = new ResearchItem("Source",newValues.source,0)
          this.research[factToUpdateId].research={description:{title:"Description",value:newValues.description}}
        }

        let nodesCopy = this.research.slice();
        this.setState({count:this.research.length, research:nodesCopy});
      }
      */

    }

    removeFact = (fact:ResearchNode) =>
    {
      const i = this.research.findIndex( f => (f.id === fact.id))
      if (i > -1)
      {
      this.research[i].isRemoved = !(fact.isRemoved);
      let nodesCopy = this.research.slice();
      this.setState({count:this.research.length, research:nodesCopy});
      }
    }


 // Graphic View



 zoom  = (zoomFactor:number) =>
 {
  this.options.zoom = this.options.zoom *zoomFactor;
  this.options.factSize = Math.ceil(zoomFactor * this.options.factSize);

  this.research.forEach( (f) => {
    f.position.x = Math.round(f.position.x * zoomFactor);
    f.position.y = Math.round(f.position.y * zoomFactor);
  })

  this.arrows.forEach((a) => {
    const startNode :ResearchNode|undefined= this.research.find((r:ResearchNode) => {return (r.id === a.fromNode)})
    const endNode :ResearchNode|undefined = this.research.find((r:ResearchNode) => {return (r.id === a.toNode)})
    a.start = startNode?{...startNode.position}:a.start
    a.end = endNode?{...endNode.position}:a.end
  })

 }

 onClickZoomOut = () =>

  {
    if (this.options.zoom > .1)
    {
    this.zoom(0.75);
    this.setState({count:this.research.length});
    }
    else
    {
      this.statusUpdate(Messages.ZoomInLimit)
    }

  }

  onClickZoomIn = () =>

  {
    const  bottomXFact = this.research.reduce( (a,b) => {
      return (b.position.x > a.position.x) ? b:a 
    },this.research[0]);

    const  bottomYFact = this.research.reduce( (a,b) => {
      return (b.position.y > a.position.y) ? b:a 
    },this.research[0]);


    if ((bottomYFact.position.y  * 1.25  < this.options.pageSize.y)  &&
       (bottomXFact.position.x  *  1.25  < this.options.pageSize.x) )
       {
        this.zoom(1.25);
        this.setState({count:this.research.length});
       }
    
    else
    {
      this.statusUpdate(Messages.ZoomInWarning)
    }

  }

statusUpdate = (message:any) =>
  {
    this.status= {...message}
    this.setState(this.status)
  }


 onClickYearUp = (e:any) =>
 /* Clicking the UP Button adds 10 years to the botton of the date range 1825 -> 1800
 */
 
 {  
   
   const  bottomFact = this.research.reduce( (a,b) => {
     return (b.position.y > a.position.y) ? b:a 
   },this.research[0]);


   const factor = (bottomFact.position.y < (this.options.pageSize.y - (1100) * this.options.zoom) ? 10 :
                  ((bottomFact.position.y < (this.options.pageSize.y - (600 * this.options.zoom)))) ?  5 :0);


   if (factor > 0)
   {
    const shift = Math.round(factor*100*this.options.zoom);  
    this.options.startingHalfDecade-= factor;

   this.arrows.forEach((a:Arrow) => 
   {a.end.y =(a.end.y + shift); 
    a.start.y= (a.start.y + shift)
    })

   this.research.forEach((a:ResearchNode) => 
   {a.position.y +=shift; })
   this.setState({count:this.research.length});
   }
   else
   {
     this.statusUpdate(Messages.ScrollDownWarning)
   }

 }

 onClickYearDown = (e:any) =>
 /* Clicking the Down Button adds 10 years to the top of the date range 1825 -> 1835
 */
 
 {  
   
   const  topFact = this.research.reduce( (a,b) => {
     return (b.position.y < a.position.y) ? b:a 
   },this.research[0]);


   const factor = (topFact.position.y  >  (1100 * this.options.zoom) ? 10 :
                  (topFact.position.y  > (600 * this.options.zoom)) ?  5 :0);
   if (factor > 0)
   {
   const shift = Math.round(factor*100*this.options.zoom); 
   this.options.startingHalfDecade+= factor;
  
   this.arrows.forEach((a:Arrow) => {a.end.y -=(shift); a.start.y-=shift})
 
   this.research.forEach((a:ResearchNode) => {a.position.y -=shift; })
   this.setState({count:this.research.length});
   }
   else
   {
     this.statusUpdate(Messages.ScrollUpWarning)
   }

 }

 
 haveIntersection = (r1:ResearchNode, r2:ResearchNode) => {
   const height = this.options.factSize
   const width = this.options.factSize

  return !(
    r2.position.x > r1.position.x + r1.width ||
    r2.position.x + r2.width < r1.position.x ||
    r2.position.y > r1.position.y + r1.height ||
    r2.position.y + r2.height < r1.position.y
  );
}

onDragEnd = (e:any) =>
  {
    
    
    const lastPos = {...e.currentTarget._lastPos}
    const factId = e.currentTarget.attrs.id
    let  fact= this.research.find((f) => {return (f.id === factId)})
    if (fact)
  
    {
      const thisFact = fact; 
      fact.position = {...lastPos};
      let nodesCopy = this.research.slice();

      // Check if the fact was dropped on another fact 
      const droppedOn =  this.research
        .filter( (e) => ((e.id != thisFact.id) && !(e.isHidden)&& !(e.isRemoved)))
        .find( (e)=> {return this.haveIntersection(thisFact,e)})

      if (droppedOn)
      {
        this.dropped = fact;
        this.droppedOn = droppedOn;
        this.droppedOnConfirm = true;
      }  
      this.setState({ research:nodesCopy}); 
    }

  }

  confirmFactMerge = (confirm:boolean) =>
  {
    if (confirm && typeof(confirm) === "boolean" )  // This gets called by Dialog Handler if yu click away on the confirm
      {
        this.dropped.isHidden = true;
        this.dropped.isHiddenId = this.droppedOn.id;
        this.arrows = this.arrows.filter( (a:Arrow) =>  ((a.fromNode != this.dropped.id) && (a.toNode != this.dropped.id)))
      }
    this.droppedOnConfirm = false;  
    this.setState({confirm:false})
  }

  confirmLinkShare = (confirm:boolean) =>
  {

    this.droppedOnConfirm = false;  
    this.setState({linkDialog:false})
  }

  visibleFact = (fact:ResearchNode) =>
  {
    if (fact.isHidden)
    {
      let f = findFact(this.research,fact.id)
      if (f)
      {
      f.isHidden =false;
      f.isHiddenId = "";
      this.setState({confirm:false});
      }
    }
  }
  
  onClickArrow = (e:any) =>
  {
      
      const targetId = e.currentTarget.getAttr('lineId');

      const a = this.arrows.filter( (a:Arrow) => 
        {return (a.id != targetId)});
      this.arrows = a;
      this.setState({arrows:a});

  }


  updateArrows = (nodeId:string, newPos:Coordinates) =>
  {
    this.arrows.forEach( (a,i) => {
      if (a.fromNode == nodeId)
      {
          a.start = {...newPos};
        }
        
    });

    this.arrows.forEach( (a,i) => {
        if (a.toNode == nodeId)
        {
            a.end = {...newPos}
        }
        
    });
  }

  onDragMove = (e:any) =>
  {

     
      let nodeMovingId = e.currentTarget.getAttr("id");
      let newPos = e.currentTarget._lastPos;
      let arrowsFrom : Arrow [] =[];

      arrowsFrom = this.arrows.filter((e:Arrow) => e.fromNode ==nodeMovingId );

      this.arrows.forEach( (a,i) => {
          if (a.fromNode == nodeMovingId)
          {
              a.start = {...newPos};
          }
          
      });

      this.arrows.forEach( (a,i) => {
          if (a.toNode == nodeMovingId)
          {
              a.end = {...newPos}
          }
          
      });
     
      this.setState({arrows:this.arrows.slice()});

     

  }

  updateSelectedNode = (idIn:string) =>
  {
      const f = (this.research.find((r) => r.id ===idIn))
      if (f)
      {
        f.isSelected = !f.isSelected;
      }
  }


  onClickYearOrderStrict = () =>
  {
    const startingHalfDecade= this.options.startingHalfDecade;

    this.research.map( (f) => {
      
      const newY = (f.year != null) ? this.calculateYForYear(f.year)  : f.position.y
      f.position.y = newY;
      this.updateArrows(f.id,{x:f.position.x,y:newY})
    })

    this.options.yearOrdering = YearOrderingType.Strict;
    this.setState({arrows:this.arrows.slice(),shapes:this.research.slice()});
    
  }

  
  onClickYearOrderLoose = () =>
  {
    const startingHalfDecade= this.options.startingHalfDecade;
    const looseIconSize = 150 * this.options.zoom;
    let nextY=0;
    let minYear = this.options.startingYear
    if (this.research.length > 0)
    {
    let r = this.research
      .sort((a,b) => {return (((a.year?a.year:0) > (b.year?b.year:0) ? 1:-1))})
     minYear = r[0].year?r[0].year: this.options.startingYear;

     r.map( (f) => {
   
        let  y = nextY;   
        const year = (f.year)?f.year:0 
        if (year > minYear)
        {
            nextY = nextY + looseIconSize;
            minYear = year
            y = nextY;
        }
     
        f.position.y =y;
        this.updateArrows(f.id,{x:f.position.x,y:y})
      })
    }

    this.options.yearOrdering = YearOrderingType.Loose;
    this.setState({arrows:this.arrows.slice(),shapes:this.research.slice()});

  }


  onClickFact = (e:any):void =>
  {
      
    if (e)
    {
    if (e.currentTarget.getType() == "Group")
          {
              if (this.nodeSelected  == true)
              {
                  if (this.nodeSelectedId != e.currentTarget.getAttr("id"))
                  {
                  let a = new Arrow(this.arrowStartCoordinates,e.currentTarget._lastPos,this.nodeSelectedId,e.currentTarget.getAttr("id"))    
                  this.arrows.push(a);
                  }
                 this.nodeSelected = false;
                 this.updateSelectedNode(this.nodeSelectedId)
    
              }
              else 
              {
              this.nodeSelected = true
              this.nodeSelectedId = e.currentTarget.getAttr("id")
              this.arrowStartCoordinates = e.currentTarget._lastPos;

              this.updateSelectedNode(this.nodeSelectedId)
 
              }
          }

      this.setState({arrows:this.arrows.slice(),shapes:this.research.slice()});
        }
    else // Special Case toggle away from Arrow Join Mode    
    {
      if (this.nodeSelected )
        {this.nodeSelected = false;
        this.updateSelectedNode(this.nodeSelectedId)
        this.setState({nodeSelected :false});
        }
    }

  }

  handleViewClick(view:Views,e:any)

  {
    if ((this.currentView == Views.Graphic) && (view==Views.Share))
    {
      this.currentView=view;
      this.passBackStageRef(this.stageRef,true)
    }
    else if (view==Views.Share) // This is obsolte PDF Create is now on a menu
    {
      this.currentView=view;
      this.setState({view:view,graphicRendered:false});
    }
    else
    {
      this.currentView=view;
      this.setState({view:view});
    }
  
  }

 

  noteChange(name:string,value:any)
  {
    if(name === "isProtected")
    {
      this.options.isProtected = value
    }
    else
    {
      this.note[name] = value;
    }

  }

 

  passBackStageRef =  (node:any,renderforShare:boolean)  =>
  {
    this.stageRef = node;

    if ((node != null) && (renderforShare))
      {
        setTimeout(()=>{
          this.stageRef.getStage().toDataURL({callback:this.callmewhendone})
        },100)
       // this.stageRef.getStage().toDataURL({callback:this.callmewhendone})
      }
 
  }
 
  graphicRenderSaved =  (dataURL:string) => {

 
    this.base64GraphicView =  dataURL; 
    this.graphicRendered = true;
  
  }

  

  clearUser = () =>
  {
    this.arrows = [];
    this.note = new Note("","","","","")
    this.research =[];

  }

  handleLogout = () =>
  {
  let self = this;
    this.props.firebase.auth.signOut().then(function() {
      self.status= {...Messages.SignOutOk};
      self.resetUser();
      self.setState(self.status)
      
    }, function(error:any) {
      self.status= {...Messages.SignOutBad}
      self.setState(self.status)
    });
  }


  callmewhendone = (dataURL:string) =>
  {
    this.base64GraphicView =  dataURL;
    
    this.setState({graphicRendered:true,view:Views.Share});
   
  }

 


  componentDidMount() {
    
    const self = this;
    this.props.firebase.auth.onAuthStateChanged((authUser:any) => {
   
      authUser
      ? FileControl.getUserName(this.props.firebase,authUser.uid).then(snapshot =>
        {
          self.userProfile = {...snapshot.val()}
        })
      : self.userProfile = {};   

      authUser
      ? FileControl.updateUserAccess(this.props.firebase,authUser.uid)
      :"";

      authUser
        ? self.setState({ authUser })
        : self.setState({ authUser: null });
 
    if (this.urlUser && this.urlResearch)
      {
      this.props.firebase.document(this.urlUser,this.urlResearch)
      .once('value').then(function(snapshot:any) {
      
        self.onFileLoad(snapshot)
      });
      }
    else
      {
        authUser
        ? self.setState({ authUser })
        : self.setState({ authUser: null });
      }
      
    })

  }

  menuClick = (name:string) =>
  {


    if (name == "Save")
    {
      if ((this.fileReference ) && (!this.options.isProtected))
      {
      

      FileControl.saveExisting(this.props.firebase,this.state.authUser.uid,this.fileReference,
        this.note,this.research,this.todo,this.arrows,this.options);
      }
      else{
        this.fileReference = FileControl.saveNew(this.props.firebase,this.state.authUser.uid,
          this.note,this.research,this.todo,this.arrows,this.options);
      }

    } 
    else if  (name ===  "Save As")
    {
      this.fileReference = FileControl.saveNew(this.props.firebase,this.state.authUser.uid,
        this.note,this.research,this.todo,this.arrows,this.options);
    }
    else if (name === "Load")
    {
      this.setState({fileDialog:true})
    }
    else if (name === "Share Link")
    {
      this.setState({linkDialog:true})
    }
    else if (name === "Create PDF")
    {
      if ((this.currentView == Views.Graphic) )
      {
        this.currentView=Views.Share;
        this.passBackStageRef(this.stageRef,true)
      }
      else
      {
      this.currentView=Views.Share;
      this.setState({view:Views.Share,graphicRendered:false});
      }
    }
    
  
  }


  checkVal(aN:ResearchNode,bN:ResearchNode)
  {
    const b = bN.year || 0;
    const a = aN.year || 0;
    if (b <a)
     return bN
  else return aN
  }
  getMinY = (data:ResearchNode[]) => {
    const a = data.reduce( this.checkVal, data[0]);
    return a.year;
  }

  onFileLoad = (snapshot:any) =>
  {

    if (snapshot.exists())
    {
    const {note,research,todo,arrows,options} =  snapshot.val()
    this.note = note || new Note("","","","","0");
    this.research = research || [];
    this.research.forEach(f => {
      f["note"] = f.note?f.note:"";
      f["isRemoved"] = f.isRemoved?f.isRemoved:false
      f["isHidden"] = f.isHidden?f.isHidden:false;
      f["isHiddenId"] = f.isHiddenId?f.isHiddenId:"";
      });
    this.todo = todo || [];
    this.arrows  = arrows || [];
    this.options = options || new Options()


   // rebuild(this.research,this.arrows,this.todo,this.options);
    

    this.options.startingYear = (this.options.startingYear ) ? this.options.startingYear: this.getMinY(this.research) || 0;
    this.options.startingHalfDecade= (this.options.startingHalfDecade)  ? this.options.startingHalfDecade: Math.floor(this.options.startingYear / 5) * 5;
    this.options.zoom = (this.options.zoom )  ? this.options.zoom : 1;
    this.options.factSize = (this.options.factSize)  ? this.options.factSize : 100;
    this.options.yearOrdering = (this.options.yearOrdering) ? this.options.yearOrdering : YearOrderingType.Strict;
    this.options.nextDisplayNumber = (this.options.nextDisplayNumber )  ? this.options.nextDisplayNumber : 1;
    this.options.isProtected = (this.options.isProtected )  ? this.options.isProtected : false
    this.status= {...Messages.FileLoaded} 

    /* If this is a load from URL then don't allow a save unless the user id and the requested one are the same
    */

    this.fileReference = (this.urlUser && this.urlResearch) ? (this.urlUser != this.props.firebase.auth.currentUser.uid ? null:snapshot.key) : snapshot.key;
    this.urlUser= null;
    this.urlResearch = null;
    this.fileSaved = false;
    this.toDoFormData = new ToDo;
    this.setState({fileDialog:false})
    }

  }

    handleFileViewClose = (open:boolean) =>
    {
      this.setState({fileDialog:open});
    }
    
  
  statusMessageClose = () =>
    {
      this.status.status = false;
      this.setState(this.status)
    }


  
  render() {

      
    
    const research= this.research.slice();
    const arrows = this.arrows.slice();
    const todo = this.todo.slice();
    const note = Object.assign({}, this.note);
    const status = Object.assign({}, this.status);
  

    return (
         <div className="App">
  <AppMenuBar version={version}  handleLogout ={this.handleLogout}  userProfile={this.userProfile}  authUser ={this.state.authUser}/>
  

        {/*File Save and Load Dialog*/}
    {this.state.authUser && this.state.fileDialog && 
      <FileDialog  open={this.state.fileDialog}  handleClose={this.handleFileViewClose}   
        onFileLoad={this.onFileLoad}  >
      </FileDialog>
    }

       {!this.state.authUser && <SignUpPage />}

       { this.state.authUser && (<div>
   
        <ToolMenuBar currentView={this.state.view} options ={this.options}  userProfile={this.userProfile}
          handlePaste =   {this.handlePaste}  addFact = {this.addFact} handleViewClick={this.handleViewClick} 
                            menuClick={this.menuClick}  />

        {this.droppedOnConfirm &&
        <ConfirmDialog title = {"Confirm Research Merge"} 
            description ={<Typography component={'span'} variant='subtitle1'>Merge Facts for Display</Typography>}
    
            onClose={this.confirmFactMerge} />
        }
          



        {this.state.linkDialog &&
        <ShareLinkModal title = {"Share Research Link"} 
           shareLink={HostURL + '?user=' +this.props.firebase.auth.currentUser.uid  +  '&research=' + this.fileReference}
    
            onClose={this.confirmLinkShare} />
        }
      
        {(this.state.view === Views.Graphic) && 
          <GraphicView research={research} arrows ={arrows} note={note}
            onDragEnd={this.onDragEnd}
            onClickFact={this.onClickFact}
            onDragMove={this.onDragMove}
            onClickArrow={this.onClickArrow}  
            passBackStageRef = {this.passBackStageRef}
            renderforShare={false}
            onClickYearUp = {this.onClickYearUp }
            onClickYearDown = {this.onClickYearDown}   
            onClickZoomOut = {this.onClickZoomOut}   
            onClickZoomIn = {this.onClickZoomIn}  
            onClickYearOrderStrict ={this.onClickYearOrderStrict}
            onClickYearOrderLoose ={this.onClickYearOrderLoose} 
            updateFact = {this.updateFact }              
            options ={this.options}

        />}

        {(this.state.view === Views.Detail) && 
          <DetailView  details={research}  
             updateFact = {this.updateFact } removeFact={this.removeFact}  visibleFact={this.visibleFact}>
           
            </DetailView>}    

        {(this.state.view === Views.ToDo) && 
         <ToDoListView 
         
          toDoFormData={this.toDoFormData}
          todos={todo}
          research={this.research}
          ClearToDoForm = {this.ClearToDoForm}
          ModifyToDo={this.UpdateToDoList}
          AddToDo = {this.UpdateToDoList} 
          ToDoSelected = {this.ToDoSelected}
          ToDoRemove={this.ToDoRemove}>
        </ToDoListView>       } 

        {(this.state.view === Views.Note) && 
        
        <NoteAddView note = {this.note}
          noteChange={this.noteChange}
          options = {this.options}/>
        }



        
    {(this.state.view === Views.Share) &&  this.state.graphicRendered  &&
        
        <div style={{width:"100%", height:"600px"}}>
        <PDFViewer width={"100%"} height={600}>
          <PDFView  research={research} todo = {todo} note={this.note}  base64GraphicView={this.base64GraphicView}/>
 
        </PDFViewer>
        </div>
        } 

{/* If we try and render the PDF from a view other than the Graphic we need to rerender the Graphic first */}

    {(this.state.view === Views.Share) && !this.state.graphicRendered    &&
          <GraphicView research={research} arrows ={arrows} note={note}
          onDragEnd={this.onDragEnd}
          onClickFact={this.onClickFact}
          onDragMove={this.onDragMove}
          onClickArrow={this.onClickArrow}  
          passBackStageRef = {this.passBackStageRef}
          renderforShare={true}
          options={this.options}>
          onClickYearUp = {this.onClickYearUp }
          </GraphicView>

    }

    { this.state.view === Views.Cloud &&
        <NameCloud 
            research={research}
            options={this.options} />
        }


       </div>)}
       {this.status.status &&
       <StatusBar id = {Math.round(Math.random()*100000)} status={status} open={status.status} onClose={this.statusMessageClose}></StatusBar>
       }
      </div>

    );
  }
}

export default withFirebase(App);
