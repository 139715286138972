// 
// <Link target="_blank" to='http://www.google.com'>Google</Link>

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link';




class ExternalURLLink extends React.Component<any,any>{

      render() {
          return  (<Link href={this.props.url}  target={'_blank'}>{this.props.title||"Link"}</Link>)
      }

    }

 export default ExternalURLLink   