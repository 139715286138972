import * as React from 'react'
import { Paper } from "@material-ui/core";

import { Image,Page, Text, View, Document, StyleSheet, Link} from '@react-pdf/renderer';
import { ResearchItem ,ResearchNode,SourceType} from '../model/ResearchNode';
import {ToDo,ToDoFormData} from "../todo/ToDo"
import {Note} from "../note/Note"
import ResearchIcon from "../icons/png/research.png"
import styled from '@react-pdf/styled-components';
import PDFViewCensus from "./PDFViewCensus"

const Fragment = React.Fragment;

import{FactHeading,FactLink,FactDetailHeading} from "./PDFViewStyles"
 

import {padNumber} from '../utility/ResearchUtil'


export const PDFViewFactSummary = (props:any) =>

//TODO This makes the heading with a right aligned Fact Id, but it generates a page break for each Fact 

/*
 <View 
    style = {{    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 35,
    margin: "14px",
    marginBottom:"5px",

    paddingBottom:"5px"
  
  }}
  >
    <Text style= {{     flexGrow: 1,
flexShrink: 1,
flexBasis: "auto",
alignSelf: "stretch", border:'solid',color: 'black', textAlign: 'left', marginRight: 30
,
   
}}> {" " +fact.type + " "   + (fact.year || "????") + " " + fact.name  + " " }</Text>
    <Text style ={{     flexGrow: 0,
flexShrink: 0,
flexBasis: "auto",
alignSelf: "stretch", textAlign: 'right',marginRight: 10 ,fontSize: 10,

    }} >{padNumber(fact.id,3)}</Text>
    

  </View>         
  */
{
  const {fact} = props
  return (
    <FactHeading>
      {padNumber(fact.displayNumber,3)}
      {" - " +fact.type + " "   + (fact.year || "????") + " " + fact.name  + " " }
    </FactHeading>
           
  )
}
        
class PDFViewFactHeader extends React.Component <any,any>{

    
    constructor(props:any)
    {
      super(props)
     
  
    }  
  
  
    render() {
     
      const {fact} = this.props;  
      
      return (
  
      
            <View>
               <PDFViewFactSummary fact={fact}/>
                

              <FactLink >{"Original Source: "}
                {fact.source &&  (fact.source.type === SourceType.URL)  &&  ( <Link src={fact.source.value}>{fact.source.title}</Link> ) }
                {fact.source &&  (fact.source.type != SourceType.URL)  &&  ( <Fragment>{fact.source.value}</Fragment> ) }       
              </FactLink > 

              <FactLink >{"Research Reference: "}
              {fact.sourceScrape && (fact.sourceScrape.type != "Internal") &&  (<Link src={fact.sourceScrape.value}>{fact.sourceScrape.title}</Link> ) }
              {fact.sourceScrape && (fact.sourceScrape.type === "Internal") &&  (<Fragment> {fact.sourceScrape.value}</Fragment> ) }
              </FactLink >
              <FactDetailHeading >Detail</FactDetailHeading >
           </View>
         
                   

      )
    }
  }
  
  export default PDFViewFactHeader
