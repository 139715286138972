

import Firebase from '../firebase/firebase';
import Note from '../note/Note'
import {ResearchNode} from '../model/ResearchNode'


class FileControl
{
  
/* These shoud probably be part of the firbase Class, just lookimng at how Static  Methods work

*/

  static getUserName(fb:Firebase,userId:string,)
  {
    return (fb
    .user(userId)
    .once('value'))
  }

  static updateUserAccess(fb:Firebase,userId:string,)
  {
    return (fb
    .user(userId)
    .update({accessed:new Date().valueOf()})
    )

  }

  static saveDocument(fb:Firebase,userId:string,docKey:string,
    note:Note,research:ResearchNode[],todo:any,arrows:any,options:any)
  {
    fb
    .document(userId,docKey)
    .set({"note":note, "research":research, "todo":todo,"arrows":arrows,"options":options})
  }

  
  static saveNew(fb:Firebase,userId:string,
      note:Note,research:ResearchNode[],todo:any,arrows:any,options:any):string
  {
      //const userId = this.state.authUser.uid;
    const indexkey =  fb.myDocs(userId).push();
    fb
    .myDocInfo(userId,indexkey.key)
    .set({project: note.project,name:note.name,version:note.version,
      created:new Date().valueOf(),lastsaved:new Date().valueOf(),numberOfFacts:research.length,isProtected:options.isProtected,year:options.startingYear})

    this.saveDocument(fb,userId,indexkey.key ||  "",note,research,todo,arrows,options)
  

    return  indexkey.key||""

  }

 
  static saveExisting(fb:Firebase,userId:string,docKey:string,
    note:Note,research:ResearchNode[],todo:any,arrows:any,options:any)
  {
    if (docKey != "")
    {
    fb
      .myDocInfo(userId,docKey)
      .update({project: note.project,name:note.name,version:note.version,
        lastsaved:new Date().valueOf(),numberOfFacts:research.length,isProtected:options.isProtected,year:options.startingYear})

      this.saveDocument(fb,userId,docKey ||  "",note,research,todo,arrows,options)

    }
  }


  static deleteDocument(fb:Firebase,userId:string,docKey:string)
  {
    fb
    .document(userId,docKey)
    .remove()
  }

  
  static deleteExisting(fb:Firebase,userId:string,docKey:string
    )
  {
    if (docKey != "")
    {
    fb
      .myDocInfo(userId,docKey)
      .remove();
      this.deleteDocument(fb,userId,docKey)
    }
  }

  load()
  {

  }

}

export default FileControl