import React from "react";
import { AppBar, Toolbar, Typography, Paper } from "@material-ui/core";
import ToDoForm from "./ToDoAdd";
import ToDoList from "./ToDoList";
import ToDo from "./ToDo"
import {findFact} from "../utility/ResearchUtil"

const ToDoView = (props:any) =>{


 const todos = props.todos.filter((e:ToDo) => {
   const fact = findFact(props.research,e.nodeId)
   const display= fact?!fact.isRemoved:true;
  return (display)
  })



  return (
  <Paper
    elevation={0}
    style={{ padding: 0, margin: 0, backgroundColor: "#fafafa" }}
  >
 <ToDoForm

        {...props}
        onInputChange={props.ModifyToDo}
        onButtonClick={props.AddToDo}
        research = {props.research}
       
 
      />
  <ToDoList
        selected = {props.toDoFormData.id}
        items={todos}
        onItemCheck={props.ToDoSelected}
        onItemRemove={props.ToDoRemove}
        research = {props.research}

      />
  </Paper>
  )

  };

export default ToDoView ;