import {DisplayNode} from './DisplayNode'
import shortid from 'shortid'
import validURL from 'valid-url';
import { StreamPriorityOptions } from 'http2';


export enum DateType {
    Exact = "Exact",
    Before = "Before",
    After = "After",
    Circa = "Circa/About"
   
}

export enum ResearchType {
    Birth = "Birth",
    Census = "Census",
    Death = "Death",
    Link = "Link",
    Wedding = "Wedding",
    Other = "Other",
}

export enum SourceType {
    URL = "URL",
    Text = "Text",
    Document = "Document",
    Other = "Other"
}



export class ResearchItem
{
    

    public order:number;
    public  title: string;
    public value:string;
    public type:string;
    constructor (title?:string,value?:string,order?:number,type?:SourceType)
    {
        this.title = title||"";
        this.value = value||"";
        this.order =order||0;

        const f =validURL.isWebUri(this.value);

        if (validURL.isWebUri(this.value))
        {
         this.type = SourceType.URL
        }
        else 
        {
            this.type = type||SourceType.Text;
        }
       
    }


}


export class ResearchNodeArray
{
    static buildForGraphicDisplay(a :ResearchNode[])
    {
        const aForDisplay = (a.filter( (r) => (!r.isRemoved && !r.isHidden)))
        const aHidden = (a.filter( (r) => (r.isHidden)))

        aForDisplay.forEach( (r) => {
            let displayNumberString = r.displayNumber.toString();

            aHidden.forEach( (e) => {
                if (e.isHiddenId == r.id)
                {
                    displayNumberString += ("," + e.displayNumber.toString())
                }
            })
            r.displayNumberString = displayNumberString;
        })

        return aForDisplay;
    }

    static buildDisplayNumber(r:ResearchNode,a: ResearchNode[])
    {
        return r.displayNumber
    }


    

}


export class ResearchNode extends DisplayNode
{
    static description(e:ResearchNode)
    {
        return e?e.nodeType+ " in " + (e.year ||" ?") + " of " + e.name:"";
    }

    static setType(node:ResearchNode,type:ResearchType|string)
    {
        node.nodeType = type;
        switch(type) { 
            case "Birth": { 
                node.type =   ResearchType.Birth;
    
               break; 
               } ;
            case "Wedding": { 
                node.type =   ResearchType.Wedding
                break; 
            } ;   
            case "Death": { 
                node.type =   ResearchType.Death
                break; 
            } ;   
            case "Census": { 
                node.type =   ResearchType.Census
                break; 
            };
            case "Link"  :{
                node.type = ResearchType.Link;
                break;
            }  
            default:
            {
                node.type =   ResearchType.Other
                break;  
            }                        
         }
    }   

    
    public displayNumber : number =0;
    public displayNumberString : string ="";

    public research :any;
    public year? :number;

    /* This is the original source doucment
    value will be a URL and then title will dscribed it ie "Parish Record"
    */
    public source :ResearchItem = new ResearchItem();   

     /* This is the  source from which it was found
     The value will be the URL and the title will described the source ie Roots Ireland
    */
    public sourceScrape : ResearchItem = new ResearchItem();
    public location : string;
    public confidence : number;
    public dateType:DateType;
    public dateCreated :Date;
    public createdBy : string;
    public type : ResearchType = ResearchType.Other;
    public isRemoved : boolean;
    public isHidden : boolean = false;  // When displayed graphicaly is represent by anoth Fact ie Birth and Baptism
    public isHiddenId : string = "" ;  // The id of the node that will display instead
    public note: string ;

    constructor(type:ResearchType|string,name:string,y?:number,location?:string)
    {
  
        super(type,name,"0");
        const id = shortid.generate();
        this.id = id;
        this.year = y;
        this.location = location|| ""
        this.research ={};
        this.confidence =100;
        this.dateType=DateType.Exact
        this.dateCreated = new Date()
        this.createdBy =""
        this.isRemoved = false;
        this.note = "";
        ResearchNode.setType(this,type);
        ResearchNode.setImage(this);
   
    }
}

export default ResearchNode;

