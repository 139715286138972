import app from 'firebase/app'
import 'firebase/auth';
import 'firebase/database';

/*
https://www.robinwieruch.de/complete-firebase-authentication-react-tutorial/#react-firebase-sign-up
*/

const config = {
    apiKey: "AIzaSyD39QZHDjp5iTQJ9cpwoI_H_vtLVurTbX0",
    authDomain: "research-59dcf.firebaseapp.com",
    databaseURL: "https://research-59dcf.firebaseio.com",
    projectId: "research-59dcf",
    storageBucket: "research-59dcf.appspot.com",
    messagingSenderId: "241163305223"
  };

  class Firebase {
    constructor() {
      app.initializeApp(config);
      this.auth = app.auth();
      this.db = app.database();
    }
    doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);


  user = uid => this.db.ref(`users/${uid}`);
  users = () => this.db.ref('users');

  myDocInfo = (uid,docid) => this.db.ref(`docindex/${uid}/${docid}`);
  myDocs = (uid) => this.db.ref(`docindex/${uid}`);

  document = (uid,docid) => this.db.ref(`documents/${uid}/${docid}`);
  documents = (uid,docid) => this.db.ref(`documents/${uid}`);


  }
  


  export default Firebase;
  