import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {IconButton, Dialog ,DialogActions,DialogTitle,DialogContent,DialogContentText, Paper} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import {ResearchNode } from '../model/ResearchNode';

import FactForm from './FactForm'

//TODO ADD and EDIT MODAL can be refactored in a single FactModal 

const styles = {
  factLabel: {
    width:"200px"
  },

  modalDetail:
  {
    margin:"0px"
  },

  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },


  iconSize :
    {
      width:"36px",
      height:"36px"
    },


};

/**
 * Standard Component for Modal Form and Button for editimg Facts
 * 
 * EditFormDialogWrapped - Add for only handel open close
 * EditFactModal - With button
 * 
 * @param {Function} updateFact called when the submit button is pressed
 * @param {class}  optional wrapper for style
 * @param {Boolean} open   required for EditFormDialogWrapped 
 * @param {Function} onCloserequired for EditFormDialogWrapped 
 * @returns 
 */

class SimpleDialog extends  React.Component<any,any>{


  constructor(props:any)
  {
      super(props);
    
      this.state = {
         open:props.open
      }
  }

  handleClose = () => {
    this.props.onClose();
    this.setState({open:false});
  };


  submit = (values:ResearchNode) => {
    
    this.props.updateFact(values)
    this.setState({open:false});
    
  }


  render() {
   
    const fact:ResearchNode = this.props.fact;
    

    const { classes, onClose, selectedValue,  ...other} = this.props;
    const isInternal = (fact.sourceScrape.type === "Internal")

    return (
      <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other}>

        <DialogTitle id="draggable-dialog-title" >
        Edit Fact Details
        </DialogTitle  >
          <DialogContent>
            <DialogContentText>
            <Paper elevation={2}  style={{ margin: 16, padding: 16 }}>
            <FactForm onSubmit={this.submit}  internal = {isInternal}  userProfile={this.props.userProfile} 
            buttonText ={"Update Fact"} fact={fact}/>
            
            

                </Paper>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
                <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                    <CloseIcon />
                </IconButton>
            </DialogActions>
      </Dialog>
    );
  }
}



 export const EditFormDialogWrapped = withStyles(styles)(SimpleDialog);

class FactEditModal  extends React.Component<any,any>{

    constructor(props:any)
    {
        super(props);
        this.state = {
          open: props.editView

        };
    }


  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({  open: false });

  };

  render() {
    
    const { classes,...other } = this.props;
    return (
      

         <EditFormDialogWrapped 
          open={this.props.open}
          onClose={this.handleClose}
         {...other}
        />
     
    );
  }
}

export default withStyles(styles)(FactEditModal);
