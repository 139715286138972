import React, { Component, Fragment } from 'react';
import { withStyles,Paper, Hidden, Menu } from '@material-ui/core/'


import {ResearchNode} from '../model/ResearchNode'
import {DetailModalPanel}  from './DetailModal'
import PopUpOrLink from '../utility/PopUpOrLink'


import FactEditModal from "../fact/FactEditModal"

import MaterialTable from 'material-table'
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';

import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';

import Clear from '@material-ui/icons/Clear'
import {Remove,Delete,Edit,Info,Undo, Dehaze,Visibility} from '@material-ui/icons/'

const styles = {
	NoteDetail: {
		margin: 20,
        padding: 20,

        overflow:'auto',
        

 
    },
    ViewDetail: {
		margin: 20,
        padding: 20,

        overflow:'auto',
        

 
	}
}

class DetailView extends React.Component<any,any>{

    fact:ResearchNode;
 
    constructor(props:any)
    {
        super(props);
        this.state =
        {
           editView:false,
           detailView:false,
           showDeleted:false
        }
        this.fact=new ResearchNode("Birth","");
         
    }

    editButton = (event:any, rowData:ResearchNode) =>
    {
        this.fact=rowData
        this.setState({editView:true})
    }

    detailButton = (event:any, rowData:ResearchNode) =>
    {
        this.fact=rowData
        this.setState({detailView:true})
    }

    updateFact = (newValues:object) =>
    {
        this.props.updateFact(newValues)
        this.setState({editView:false})

    }

    removeButton  = (event:any, rowData:ResearchNode) =>
    {
        this.props.removeFact(rowData)
        // this.setState({editView:true})
    }

    visibleButton  = (event:any, rowData:ResearchNode) =>
    {
        this.props.visibleFact(rowData)
        // this.setState({editView:true})
    }


    toggleDeleteButton = () =>
    {
        this.setState({showDeleted:!this.state.showDeleted})
    }

    onCloseFactEdit = () =>
    {
        this.setState({editView:false})
    }

    onCloseFactDetailView = () =>
    {
        this.setState({detailView:false})
    }

      render() {
   
        const details = (this.state.showDeleted  === true) ?
            this.props.details.filter((element:ResearchNode) => element.isRemoved ===true):
            this.props.details.filter((element:ResearchNode) => element.isRemoved !=true)

        const className = this.props.classes.NoteDetail;

        const tableTitle =  (this.state.showDeleted  === true) ?
          "List of Facts Removed from Research":
          "List of Active Facts";

        return (
          <Paper className={this.props.classes.ViewDetail}>
             {this.state.editView && 
             <FactEditModal open={this.state.editView} 
             fact={this.fact} updateFact={this.updateFact} factId={this.fact.id} onClose={this.onCloseFactEdit}>

             </FactEditModal>
             }

            {this.state.detailView && 
            <DetailModalPanel open={this.state.detailView}
                            detail = {this.fact}
                            onClose={this.onCloseFactDetailView} >
             }>

             </DetailModalPanel>
             }

            <MaterialTable

              icons={{ 
                Check: Check,
                DetailPanel: ChevronRight,
                Export: SaveAlt,
                Filter: FilterList,
                FirstPage: FirstPage,
                LastPage: LastPage,
                NextPage: ChevronRight,
                PreviousPage: ChevronLeft,
                Search: Search,
                ThirdStateCheck: Remove,
                ResetSearch:Clear
              }}



              columns={[ 
              {title: 'Id', field: 'displayNumber' ,type:"numeric",searchable:true,filtering:false,sorting:true,
                cellStyle:{maxWidth:100,padding:"5px", margin:"5px", },
                headerStyle: {maxWidth:"100",padding:"5px", margin:"5px"}
               },
              {title: 'Year', field: 'year' ,type:'numeric',searchable:true,filtering:true,sorting:true},
              {title:'Type',field: 'type',searchable:true,filtering:true,sorting:true},
              {title:"Name",field:'name',searchable:true,filtering:true,sorting:true},
              {title:'Location',field:'location' ,type:"string",filtering:true,sorting:true},
              
              {title:'Source',field:'location' ,type:"string",filtering:false,sorting:false,

                render:rowdata => <PopUpOrLink anchor={<p>Internal</p>} {...rowdata.sourceScrape}/>
                },

              {title:'Source Original',field:'location' ,type:"string",filtering:false,

              render:rowdata => <PopUpOrLink anchor={<p>Internal</p>} {...rowdata.source}/>
                }

              ,
              {title:'Hidden',field:'isHidden' ,type:"boolean",filtering:true
              }
             
            ]}
              
              data={details}
    
              title={tableTitle}
              options={{
                toolbar: true,
                paging: false,
                filtering: true,
                actionsColumnIndex: -1
          
              }}

              actions={[
                {
                  icon: Info,
                  tooltip: 'Display Fact Detail',
                  onClick: this.detailButton
                },

                (rowData:any)  => (
                {
                  icon: Visibility,
                  tooltip: 'Make Fact Visible',
                  onClick: this.visibleButton,
                  disabled :!rowData.isHidden
                }),

                {icon: Edit,
                tooltip: 'Edit Fact',
                onClick: this.editButton
                               
                },

                {
                disabled:this.state.showDeleted,
                icon: Delete,
                tooltip: 'Remove Fact',
                onClick: this.removeButton          
                },
                             
                {
                  disabled:!this.state.showDeleted,
                  icon: Undo,
                  tooltip: 'Restore Fact',
                  onClick: this.removeButton          
                  },

                {
                // iconProps:!this.state.showDeleted ? ({style:{visibility:"hidden"}}):{},
                disabled:this.state.showDeleted,  
                icon: Delete,
                isFreeAction : true,
                tooltip: 'Show Removed Facts',
                onClick: this.toggleDeleteButton          
                },

                {
                  // iconProps:this.state.showDeleted ? ({style:{visibility:"hidden"}}):{},   
                  disabled:!this.state.showDeleted,        
                  icon: Check,
                  isFreeAction : true,
                  tooltip: 'Show Active Fact',
                  onClick: this.toggleDeleteButton          
                  }


             
                      
              ]
            }

              />
    

        
          </Paper>

        );   

      }
}

export default withStyles(styles)(DetailView);