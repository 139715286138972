import React, { Component, Fragment } from 'react';
import Konva from 'konva';
import ResearchIcon from './icons/png/research.png'
import { withStyles } from '@material-ui/core/styles'
import { render } from 'react-dom';
import { Stage, Layer, Rect, Text ,Image,Group, Line} from 'react-konva';


import useImage from 'use-image'
import {Coordinates,Arrow} from "../model/DisplayNode"
import {PageA4P,PageA5L,IconLayout} from "../page/Page"

import GraphicToolBar from "./GraphicToolBar"
import {Paper} from "@material-ui/core"

import Options, { YearOrderingType } from "../model/Options"
import { MissedVideoCallRounded, LeakAdd } from '@material-ui/icons';
import LeftClickToggle from './LeftClickToggle';
import {DetailModalPanel} from '../detail/DetailModal'
import {ResearchNode,ResearchNodeArray} from '../model/ResearchNode'
import {findFact} from '../utility/ResearchUtil'
import FactEditModal from "../fact/FactEditModal"

const FactImage = (props:any) => {
    let u = props.url;
    let s = props.selected;
    let o:Options = props.options;
    const [image] = useImage(u);
    return <Image width={o.factSize} height={o.factSize}  x={props.x} y={props.y}
             stroke={'grey'}    strokeWidth={s?2:0} image={image} />;
  };



const styles = {
	NoteGraphic: {
		margin: 20,
        padding: 20,
        marginTop: 0,
        border:'solid',
        
        borderColor: '#3f51b5',
        borderWidth: 'thin',
        overflow:'auto',
        height :'600px'

 
	}
}


export function calculateAngle(s:Coordinates,e:Coordinates):number

/*
public static double angleOf(PointF p1, PointF p2) {
    // NOTE: Remember that most math has the Y axis as positive above the X.
    // However, for screens we have Y as positive below. For this reason, 
    // the Y values are inverted to get the expected results.
    final double deltaY = (p1.y - p2.y);
    final double deltaX = (p2.x - p1.x);
    final double result = Math.toDegrees(Math.atan2(deltaY, deltaX)); 
    return (result < 0) ? (360d + result) : result;
}

https://stackoverflow.com/questions/2676719/calculating-the-angle-between-the-line-defined-by-two-points
*/


/*     
    Anti clock wise 0 is three oclock, 90 is 12 o'clock,
     

*/
{
    let dY = s.y - e.y;
    let dX = e.x - s.x;

    let result = Math.atan2(dY,dX);
    result = result * 180 / Math.PI
    return (result < 0 ? (result+360):result)
// return result 
  
}


const buildYearsStrict = (start:number,options:Options) =>
{
    let years = [];
    const yearMarks = (Math.round(options.pageSize.y/options.zoom/500));
     for (var i=0;i<yearMarks;i++)
    {
       const y = 1+(i*Math.round(5* IconLayout.iconSize * options.zoom));

        if (y<options.pageSize.y)
        {
        years.push(<Text key={i} text={(start+(i*5)).toString()} fontSize={Math.round(IconLayout.ribbonFontSize*options.zoom)} fill={'grey'} align={'left'}  y={y} x={0} draggable = {false}
        width={Math.round(100*options.zoom)} ></Text>)
        }
    }
    return years;
}


const buildYearsLoose = (start:number,options:Options,research:ResearchNode[]) =>
{
    let years : JSX.Element[] =[];
    let currentYear:number = 0; // This is the year that we have an icon on
    let currentYearLabel:number =0; // This the last year that we added a lable for
    let nextY =0;
    let facts = research.slice();
    const yearGap = Math.round(IconLayout.iconSpacingLoose * options.zoom)

    facts
        .sort((a,b) => {return (((a.year?a.year:0) > (b.year?b.year:0) ? 1:-1))})
        .map((f) => {
        const sYear:string = Number(f.year)?(f.year?f.year.toString():"0"):"0";
        const thisYear = parseInt(sYear);

        if (thisYear >= (currentYearLabel+5))
        {
            years.push(<Text key={f.id} text={thisYear.toString()} fontSize={Math.round(IconLayout.ribbonFontSize*options.zoom)} fill={'grey'} align={'left'}  y={nextY} x={0} draggable = {false}
            width={Math.round(100*options.zoom)} ></Text>)
            nextY += yearGap;
            currentYear = thisYear;
            currentYearLabel = thisYear;
        }
        else if (thisYear > currentYear)
        {
            nextY += yearGap;
            currentYear = thisYear;
        }
    })
    
    return years;
}

const BuildYearRibbon = (props:any) =>
{
    const options : Options = props.options;
    const startYear  =   options.startingHalfDecade;
    const facts = props.research;

    return (
    <Group draggable={false}  x={0} y={0}>
    {options.yearOrdering === YearOrderingType.Strict &&  buildYearsStrict(startYear,options)}
    {options.yearOrdering === YearOrderingType.Loose &&  buildYearsLoose(startYear,options,facts)}    
    </Group>
    )
}


 class GraphicView extends React.Component<any,any>{


  
    stageRef:any;
    leftClick : LeftClickToggle;
    detailFact : ResearchNode[]=[];
    editFact : ResearchNode = new ResearchNode("","");

    constructor(props:any)
    {
        super(props);

        
   
        this.stageRef={};
        this.leftClick = LeftClickToggle.Detail;
 
        this.state =
        {
            arrows:[],
            research:[],
            leftClickToggle:LeftClickToggle.Detail,
            detailView:false,
            editView:false
        }
        
    }



    fixStartStop(start:Coordinates,end:Coordinates):number[]

    /* Calculate the angel between the lines , and the decide on the best sides

    */

    {
 
        
        let c = [0,0,0,0]
  
        let a = calculateAngle(start,end)
        const o:Options = this.props.options;
        const bottom = o.factSize
        const mid = Math.round(o.factSize/2) 
        const bottomText = o.factSize+Math.round(30 * o.zoom) 
        // Top Left is 0,0
        if (((a>=0) && (a <45)) 
            || 
            ((a>=315) && (a <=360)))// RHS To LHS
        {
            c[0]=start.x+bottom;
            c[1]=start.y+mid;
            c[2]=end.x;
            c[3]=end.y+mid;
        }
        else if ((a>=45) && (a <135)) // Top to Bottom
        {
            c[0]=start.x+mid
            c[1]=start.y;
            c[2]=end.x+mid;
            c[3]=end.y+bottomText; 
        }
        else if ((a>=135) && (a <225)) // LHS to RHS
        {
            c[0]=start.x;
            c[1]=start.y+mid;
            c[2]=end.x+bottom;
            c[3]=end.y+mid; 
        }
        else if ((a>=225) && (a <315)) // Bottom to Top
        {MissedVideoCallRounded
            c[0]=start.x+mid;
            c[1]=start.y+bottomText;
            c[2]=end.x+mid;
            c[3]=end.y; 
        }        
        return c;

    }

    onClickLeftClickToggle = (name:LeftClickToggle) =>
    {
        if ((this.leftClick === LeftClickToggle.Join) && (name != LeftClickToggle.Join))
        {
            this.props.onClickFact(undefined)
        }
        this.leftClick = name

         
    }

    onClickFact = (e:any):void =>
    {
        if (this.leftClick === LeftClickToggle.Join)
        {
        this.props.onClickFact(e)
        }
        else
        {
            const factId = e.currentTarget.getAttr("id");
            const detailFact :ResearchNode|undefined = this.props.research.find((r:ResearchNode) => {return (r.id === factId )})
            if (detailFact)
            {
            this.detailFact.push(detailFact);
            const hiddenFacts = this.props.research.filter((r:ResearchNode) => r.isHiddenId === detailFact.id);
            this.detailFact = this.detailFact.concat(hiddenFacts);
            this.setState({detailView:true})
            }
        }

    }

    onCloseFactDetailView = () =>
    {
        this.detailFact =[];
        this.setState({detailView:false})
    }


      componentDidMount() 
      {
        this.props.passBackStageRef(this.stageRef,this.props.renderforShare)
      }

      
      componentWillUnMount() 
      {
        this.props.passBackStageRef(null)
      }

    
      
      onCloseFactEdit = () =>
      {
          this.setState({editView:false})
      }

      updateFact = (newValues:object) =>
      {
          this.props.updateFact(newValues)
          this.setState({editView:false})
  
      }

      onClickEditButton =(id:string) =>
      {
        const fact = findFact(this.props.research,id);
        if (fact)
        {
        this.editFact = fact;    
        this.setState({editView:true})
        }
      }

    render() {

  
        const options:Options = this.props.options;
        const research= ResearchNodeArray.buildForGraphicDisplay(this.props.research);

        const arrows= this.props.arrows.filter((e:Arrow) => {
            const fromFact = findFact(this.props.research,e.fromNode);
            const toFact = findFact(this.props.research,e.toNode);
            if (fromFact && toFact)
            {
            if ((fromFact.isRemoved)  || (toFact.isRemoved))
                {
                    return false;
                }
            else 
                return true;
            }
            else
            {
                return false;
            }
            
        });

        const leftClickToggle = this.state.leftClickToggle;

        const groupList = research.map((e:ResearchNode,index:number) =>
        {
            
            const x=e.position.x;
            const y=e.position.y;
            const labelPosition = options.factSize +1 + Math.ceil(IconLayout.idFontSize*options.zoom);  // Was Math.ceil((IconLayout.idFontSize+IconLayout.iconSize)*options.zoom)}
            const factsize=options.factSize;

            return (

            <Group  key ={e.id} id={e.id} draggable = {true}  onClick={this.onClickFact} name={e.name} onDragMove={this.props.onDragMove}
                onDragEnd={this.props.onDragEnd} x={e.position.x} y={e.position.y}  width={e.name.length} 
                

                opacity={e.confidence/100}  >

                <FactImage url={e.image} selected={e.isSelected} draggable = {false} options={this.props.options} 
                     x={0} y={Math.ceil(IconLayout.idFontSize*options.zoom)}/> 
                <Text text={e.displayNumberString} fontSize={Math.ceil(IconLayout.idFontSize*options.zoom)} align={'left'} 
                     y={0} x ={IconLayout.idOffset} draggable = {false} ></Text>       
                <Text text={e.name} fontSize={Math.ceil(IconLayout.labelFontSize*options.zoom)} align={'center'}  
                y={labelPosition}  x ={IconLayout.labelOffset} draggable = {false}
                     ></Text>
            </Group> 
        )})
            

        const arrowList = arrows.map((e:Arrow,index:number) =>
        (
        <Line   key ={e.id} draggable = {false}  onClick={this.props.onClickArrow} stroke={'black'} hitStrokeWidth={Math.round(5*IconLayout.arrowWidth*options.zoom)} 
             points = {this.fixStartStop(e.start,e.end)}  strokeWidth ={Math.round(IconLayout.arrowWidth*options.zoom)}  lineCap={'round'} lineId={e.id} >
        </Line> 
    ));     

        const className = this.props.classes.NoteGraphic;

        const pageSize = PageA4P;

        return (
            <Paper elevation={2}  style={{ margin: 16}}>
                <GraphicToolBar options={options}  name={this.props.note.name}
                                onClickYearUp={this.props.onClickYearUp}
                                onClickYearDown = {this.props.onClickYearDown}
                                onClickZoomOut = {this.props.onClickZoomOut}
                                onClickZoomIn = {this.props.onClickZoomIn} 
                                onClickLeftClickToggle = {this.onClickLeftClickToggle}
                                leftClickToggle = {leftClickToggle}
                                onClickYearOrderStrict ={this.props.onClickYearOrderStrict}
                                onClickYearOrderLoose ={this.props.onClickYearOrderLoose}                                
                                ></GraphicToolBar>


            {this.state.detailView && 
                <DetailModalPanel open={this.state.detailView}
                                detail = {this.detailFact}
                                onClickEditButton = {this.onClickEditButton}
                                onClose={this.onCloseFactDetailView} >
                }>

                </DetailModalPanel>
             }

            {this.state.editView && 
             <FactEditModal open={this.state.editView} 
             fact={this.editFact} updateFact={this.updateFact} factId={this.editFact.id} onClose={this.onCloseFactEdit}>

             </FactEditModal>
             }

                <Stage className = {className}  draggable={false} width={PageA4P.x} height={PageA4P.y}
                ref={node => { 
                    this.stageRef = node;
                    if (node == null)
                    {
                        this.props.passBackStageRef(null,false)
                    }
                    }}>
                
                <Layer>
                    
                    {this.props.options.startingHalfDecade!= 0 && <BuildYearRibbon options={this.props.options} research={this.props.research}></BuildYearRibbon>}
                    
                        {arrowList}
                        {groupList}

                </Layer>

                </Stage>
            </Paper>
        );   

      }
}
    
export default withStyles(styles)(GraphicView);