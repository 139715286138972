import {ResearchNode, ResearchItem} from "../model/ResearchNode"
import {Arrow} from "../model/DisplayNode"
import {ToDo} from "../todo/ToDo"
import {Options} from "../model/Options"
import shortid from 'shortid'


export const  padNumber = (n:number,width:number) =>
{
  return n
  .toString()
  .padStart(width,"0")
}

export const yearSort = (a:ResearchNode,b:ResearchNode) =>
{
    return (((a.year?a.year:0) > (b.year?b.year:0))? 1:-1)
}

export const findFact= (facts:ResearchNode[], id:string|number):ResearchNode|undefined => {
    const f = facts.find( (f) => {
        return (f.id === id.toString())})
    return f
}


export const rebuild = (fIn :ResearchNode[],aIn : Arrow[],tIn : ToDo[], o:Options) =>
{
  
        tIn.forEach(t => {
          t.id = shortid.generate();
        });
        aIn.forEach(t => {
          t.id = shortid.generate();
        });
        fIn.forEach((f,i) => {
          const newId = shortid.generate();
          const oldId = f.id;
          tIn.forEach(t => {
            t.nodeId = (t.nodeId === oldId) ? newId : t.nodeId;
          });
          aIn.forEach(a => {
            a.fromNode = (a.fromNode === oldId) ? newId : a.fromNode;
            a.toNode = (a.toNode === oldId) ? newId : a.toNode;
          });
          f.id = newId;
          f.displayNumber =i+1;
        });   
        o.nextDisplayNumber = fIn.length+1;
    

}