//2480 pixels x 3508 pixels

export interface PageDetail
{
    x:number
    y:number
    years: number // Number of years that can be displayed on a page at 100px per year 
}

class CPageDetail implements PageDetail
{
    public x:number
    public y:number
    public years:number
    constructor(x:number,y:number)
    {
        this.x=x;
        this.y=y;
        this.years = Math.floor(y/100)
    }
}

// http://www.graphic-design-employment.com/a4-paper-dimensions.html

/*
Standard ISO A4 paper dimensions are:

210mm x 297mm
8.27" x 11.69"
Equivalent A4 paper dimensions in pixels at 300 DPI and 72 DPI respectively are:

2480 pixels x 3508 pixels (print resolution)
595 pixels x 842 pixels (screen resolution)
*/


/* The page is used for the Graphic Window and is a fixed size

So on a normal A4 page who will see 27 Years, you can use the zoom key to increase that Zoom In
and the Year Arrows to move the decade period that is being shown

If the oldest fact is 1890 then the page will show 
*/

export const PageA4P :CPageDetail = new CPageDetail(2280,2780) // A4 Portrait Page with a 100 px margin at 300 DPI
/* The A4 is smaller then the actual page the PDF render has a hissy if the picture + heading is larger then the page
export const PageA5L :PageDetail = {x:2280,y:1040} // A5 LandcapeP age with a 100 px margin */


export const PageA5L :CPageDetail = new CPageDetail(2280,1040) // A5 LandcapeP age with a 100 px margin


export const IconLayout ={

    idFontSize :35,
    idOffset : -30,
    labelFontSize :35,
    labelOffset : -30,
    iconSize : 100,
    iconSpacingLoose : 150,
    ribbonFontSize : 35,
    arrowWidth : 5

}