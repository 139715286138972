import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button';

import {Table,TableCell,TableBody,TableHead,TableRow} from '@material-ui/core/';

import {ResearchNode} from '../model/ResearchNode'
import ExternalURLLink from '../utility/ExternalURLLink'
import DetailModal from './DetailModal'

import SimplePopover from "../utility/SimplePopover"

function sortByOrder(a:any,b:any)
{
 if (a.order > b.order )
  return 1
else 
  return -1;
}

const CensusDetailLine = (props:any) =>
{
    const row = Object.values(props.detail.value).sort(sortByOrder);

    return (
    <TableRow>
    {row.map( (c:any,i:number) => <TableCell key={i}>{c.value}</TableCell>)}
        </TableRow>)
    
}

const CensusDetail = (props:any) =>
{
    const {detail} = props
    const headings = Object.values(detail[0].value).sort(sortByOrder);

    return <Fragment>
        <Table>
        <TableHead>
            <TableRow>
                {headings.map( (c:any,i:number) => <TableCell key={i}>{c.title}</TableCell>)}
            </TableRow>
        </TableHead>
        <TableBody>
            {detail.map((r:any,i:number) =><CensusDetailLine key={i} detail={r}></CensusDetailLine>)}      
            </TableBody>
        </Table>
        </Fragment>
    
}

export default CensusDetail; 

