import React from 'react';
import SimplePopover from "../utility/SimplePopover"
import ExternalURLLink from '../utility/ExternalURLLink'

const styles = {
	NoteDetailLine: {


 
	}
}

const PopUpOrLink  = (props:any) =>
{
    if (props.type === "URL")
    {
    return <ExternalURLLink url={props.value} title={props.title}/>
    }
    else if (props.value && props.value.length !=0)
    {
    return <SimplePopover content ={props.value} anchor ={props.anchor}/>
    }
    return <p>n/a</p>
}

export default PopUpOrLink