import React, { Component, Fragment, SyntheticEvent } from 'react';
import {Snackbar,withStyles,Button,IconButton} from '@material-ui/core/'
import {Close} from '@material-ui/icons'
import Status from '../model/Status'
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber'

const styles = (theme:any) => ({
    close: {
      padding: theme.spacing.unit / 2,
    },

    success: {
      backgroundColor: green[600],
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    info: {
      backgroundColor: theme.palette.primary.dark,
    },
    warning: {
      backgroundColor: amber[700],
    },
    icon: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing.unit,
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
    margin: {
      margin: theme.spacing.unit,
    },
  });

  
class StatusBar extends React.Component<any,any>{

    state = {
        open: this.props.status.status,
      };
    

    
      handleClose = (event:SyntheticEvent,reason:string) => {
        
        if (reason=== 'clickaway') {
          return;
        }
   
         this.props.onClose()
        // this.setState({ open: false });
    
    }

    handleClick = (event:any) => {
      
 
        this.props.onClose()
        // this.setState({ open: false });
      }


render()

{
    const {classes} = this.props;
    const status:Status = this.props.status
    const Icon = status.icon;
    const iconClassName = classes.icon + " " + classes.iconVariant;
    const snackbarClassName = classes[status.type] + " " + classes.margin

    return(
    <Snackbar
    className ={snackbarClassName }
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    autoHideDuration={6000}
    open={status.status}
    onClose={this.handleClose}
    ContentProps={{
      'aria-describedby': 'message-id',
    }}
    message={<span id={this.props.id}><Icon className={iconClassName}></Icon>{status.message}</span>}
    action={[

      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        className={classes.close}
        onClick={this.handleClick}
      
      >
        <Close className={classes.icon}/>
      </IconButton>,
    ]}
  />
    )

}

}

export default withStyles(styles)(StatusBar);


