import React from "react";
import {ResearchNode} from "../model/ResearchNode"
import {findFact} from "../utility/ResearchUtil"

import {
  List,
  ListItem,
  Checkbox,
  IconButton,
  ListItemText,
  ListItemSecondaryAction
} from "@material-ui/core";

import DeleteOutlined from "@material-ui/icons/DeleteOutlined";

const TodoListItem = (props:any) => {

  const fact:ResearchNode|undefined = (props.nodeId)? findFact(props.research,props.nodeId):undefined;

  return (
  <ListItem divider={props.divider}  selected={props.selected} >

    <ListItemText onClick={props.onCheckBoxToggle} primary={  ((fact)?(ResearchNode.description(fact) + " - "):"") + props.note  } />
    <ListItemSecondaryAction>
      <IconButton aria-label="Delete Todo" onClick={props.onButtonClick}>
        <DeleteOutlined />
      </IconButton>
    </ListItemSecondaryAction>
  </ListItem>
  )
}


export default TodoListItem;