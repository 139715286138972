export const GlobalStyles = {

	view: {
		margin: 20,
        padding: 20,
        overflow:'auto',

	}
}

export default  GlobalStyles;