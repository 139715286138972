
import * as React from 'react'
import { Paper,TextField, Checkbox,FormControl,InputLabel,Select,MenuItem,FormHelperText,FormLabel,Radio,RadioGroup,Button,FormControlLabel } from "@material-ui/core";
import {Note} from "./Note"

class NoteAddView extends React.Component <any,any>{

  note :Note  
  constructor(props:any)
  {
    super(props)
    this.note=props.note;
    this.state ={...props.note,...props.options}

  }  

 
 
  customInputHandler = (event:any) => {
    // the form will update the field as usual, and then call this handler
    // if you want to have complete control of the field, change the "value" prop to "defaultValue"
    let name:string = event["currentTarget"].name
    let value:string|boolean = name==="isProtected"?event.currentTarget.checked:event["currentTarget"].value

    
    this.props.noteChange(name,value);
    this.setState({[name]:value})

  }
 
 
 
 

 
/* You need this to handle the case when the note information is updated ( from say a load)
the view is rendered but the state values have not changed, setting state here doesn't cause a rerender
*/
  componentWillReceiveProps(nextProps:any) {

    if ((nextProps.note.name != this.state.name) ||  
    (nextProps.note.description != this.state.description) ||
    (nextProps.note.summary != this.state.summary) ||
    (nextProps.note.project != this.state.project) ||
    (nextProps.note.version != this.state.version)  ||
    (nextProps.options.isProtected != this.state.isProtected)
    )
    {
      const newState = {...nextProps.note,...nextProps.options}
      this.setState(newState)
    }
    
  }

  render() {
   
    return (

          <Paper elevation={2}  style={{ margin: 16, padding: 16 }}>
          <h2>{this.state.name}</h2>
          <FormControlLabel control={
            <Checkbox name="isProtected"
              onChange ={this.customInputHandler} 
              checked={this.state.isProtected}
              />} 
              label="Protected" 
              />
          <TextField label="Name" type="text" name="name"  
            value={this.state.name} data-validators="isRequired" fullWidth = {true} margin={'normal'}
            onChange={this.customInputHandler} />
          <TextField label="Project" type="text" name="project"  
            value={this.state.project} fullWidth = {true} margin={'normal'}
            onChange={this.customInputHandler} />
          <TextField label="Version" type="text" name="version"  
            value={this.state.version}  fullWidth = {true} margin={'normal'}
            onChange={this.customInputHandler} />
          <TextField label="Description"  multiline fullWidth = {true}  rows="4" type="text" name="description" value={this.state.description}
            onChange={this.customInputHandler} />      
          <TextField label="Summary" type="text" name="summary" value={this.state.summary}  multiline fullWidth = {true}  rows="15"
            onChange={this.customInputHandler} /> 

   

        </Paper>

    )
  }
}

export default NoteAddView 