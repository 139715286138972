import React, { Component } from 'react'
import Select from 'react-select'
import {ResearchNode} from "../model/ResearchNode"


class ResearchPicker extends React.Component<any,any>{
    constructor(props:any)
    {
        super(props)
    }

onChange = (e:any) =>
{
    this.props.onChange(e?e.value:-1)
    
}

render  = () => 
{
  
    const options = this.props.research.map( (e: ResearchNode) => ( {value:e.id,label: ResearchNode.description(e)}));
    const value=options.filter((o : any) => o.value === this.props.value)
    return (
    
    <Select options={options} onChange={this.onChange} value={value} 
        placeholder={'Choose Fact to Add ToDo to'}
        isClearable={true}
        isSearchable={true}/>
    )
    }   


}

export default ResearchPicker