import React, { Component, Fragment } from 'react';
import { Image,Page, Text, View, Document, StyleSheet, Link} from '@react-pdf/renderer';
import PDFViewFactHeader from "./PDFViewFactHeader"

function sortByOrder(a:any,b:any)
{
 if (a.order > b.order )
  return 1
else 
  return -1;
}

const CensusDetailLinePDF = (props:any) =>
{
    const row = Object.values(props.detail.value).sort(sortByOrder);

    return (
        <View style={[styles.row]}>
    {row.map( (c:any,i:number) => <Text style={[styles.headerText, styles.cell]} key={i}>{c.value}</Text>)}
        </View>)
    
}

const PDFViewCensus = (props:any) =>
{
    const {fact} = props;
    const detail = fact.research.CensusDetail.value;
    const headings = Object.values(detail[0].value).sort(sortByOrder);

    return <Fragment>
            <View  wrap={false} >
      
              <PDFViewFactHeader fact={fact}/>
              <View style={[styles.row, styles.header]}>
                  
                  {headings.map( (c:any,i:number) => <Text style={[styles.headerText, styles.cell]} key={i}>{c.title}</Text>)}
                  
              </View>   
                  {detail.map((r:any,i:number) =><CensusDetailLinePDF key={i} detail={r}></CensusDetailLinePDF>)}       
            </View>
          </Fragment>
    
}


const styles = {
    page: { flexDirection: "column", padding: 25 },
   table: {
     fontSize: 10,
     width: 550,
     display: "flex",
     flexDirection: "column",
     justifyContent: "flex-start",
     alignContent: "stretch",
     flexWrap: "nowrap",
     alignItems: "stretch"
   },
   row: {
     display: "flex",
     flexDirection: "row",
     justifyContent: "space-around",
     alignContent: "stretch",
     flexWrap: "nowrap",
     alignItems: "stretch",
     flexGrow: 0,
     flexShrink: 0,
     flexBasis: 35
   },
   cell: {
 
     flexGrow: 1,
     flexShrink: 1,
     flexBasis: "auto",
     alignSelf: "stretch"
   },
   header: {
     backgroundColor: "#eee"
   },
   headerText: {
     fontSize: 8,
     fontWeight: 1200,
     color: "#1a245c",
     margin: 2,
     maxWidth:"100px",
     minWidth:"0px"
   },

   tableText: {
     margin: 10,
     fontSize: 8,
     maxWidth:10,
     color: "neutralDark"
   }
 };
 
 

export default PDFViewCensus; 

