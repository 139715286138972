import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Dialog from '@material-ui/core/Dialog';
import blue from '@material-ui/core/colors/blue';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography'
import {Paper} from '@material-ui/core'
import {ResearchNode,ResearchItem,DateType } from '../model/ResearchNode';



import ExternalURLLink from '../utility/ExternalURLLink';
import { CardContent, ExpansionPanelDetails } from '@material-ui/core';
import LinkSVG from '../icons/svg/link.svg'

import ToDoButton from "../icons/svg/ToDo.svg"
import EditButton from "../icons/svg/edit.svg"
import InfoButton from "../icons/svg/info.svg"
import ExpandButton from "../icons/svg/expand.svg"

import SimplePopover from "../utility/SimplePopover"
import CensusDetail from "./CensusDetail"

import {Tab,Tabs,AppBar} from '@material-ui/core'
import {padNumber} from '../utility/ResearchUtil'

const styles = {
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },

  modalDetail:
  {
    margin:"0px"
  },

  iconSize:
  {
    height:"16px",
    width:"16px"
  }
};

function sortByOrder(a:any,b:any)
{
 if (a.order > b.order )
  return 1
else 
  return -1;
}


const  FactModalContent = (props:any) =>
{

  
  const detail:ResearchNode = props.detail;

    const { classes, onClose, selectedValue,  ...other} = props;


    const researchByOrder = Object.values(detail.research).sort(sortByOrder);   
   
    
    return (


      <Fragment>
      <DialogTitle id="draggable-dialog-title">
      <Card className={classes.card}>
        <CardHeader
          avatar={
            <Avatar src={detail.image}></Avatar>
          }
          title={padNumber(detail.displayNumber,3) + " " + detail.name}

          subheader={<Fragment> 
            {detail.year && <Typography>{
              (detail.dateType === DateType.Exact ?"": detail.dateType + " " )+ detail.year}</Typography>}
          {detail.location && <Typography>{detail.location}</Typography>}
          {detail.note && <Typography>{detail.note}</Typography>}
          </Fragment>}

          action={
            <span>
     <Button size = {"small"} className={classes.menuButton} color="inherit" aria-label="Menu"  ><img className = {classes.iconSize} src={ToDoButton}/></Button>  

     {props.onClickEditButton &&            
     <Button size = {"small"} className={classes.menuButton} color="inherit" aria-label="Menu"  ><img className = {classes.iconSize} src={EditButton}
     onClick={ () => {props.onClickEditButton(detail.id)}}/></Button>               }

     {detail.source && detail.source.type == "URL"  && <Button size = {"small"} className={classes.menuButton} color="inherit" aria-label="Menu"  href={detail.source.value} target={"_blank"}>
     <img className = {classes.iconSize} src={LinkSVG}/></Button> }

     {detail.source && detail.type == "Link"  && <Button size = {"small"} className={classes.menuButton} color="inherit" aria-label="Menu"  href={detail.source.value} target={"_blank"}>
     <img className = {classes.iconSize} src={LinkSVG}/></Button> }

     {detail.source && detail.source.type != "URL"  && detail.source.value && detail.source.value.length !=0 &&
      <SimplePopover content={detail.source.value} anchor ={<Button size = {"small"} className={classes.menuButton} color="inherit" aria-label="Menu"  >
      <img className = {classes.iconSize} src={InfoButton}/></Button>}/>}

      <Button size = {"small"} className={classes.menuButton} color="inherit" aria-label="Menu"  ><img className = {classes.iconSize} src={ExpandButton}
      onClick ={props.expandClick}/></Button>               

 


           </span>
             
          }
          

        />
      
        </Card>

      </DialogTitle>
          <DialogContent>
            <DialogContentText>

            {researchByOrder
         
              .map((r:any,idx:any) => (
                <Fragment key={idx}>
               {r.type != "CensusDetail"  &&  <p key={idx} className={classes.modalDetail}> {r.title + "  -  " + r.value} </p>}
               {r.type === "CensusDetail"  &&  <p key={idx} className={classes.modalDetail}> 
                  <CensusDetail detail={r.value}></CensusDetail> </p>}
                </Fragment>
               ))}


            </DialogContentText>
          </DialogContent>
          </Fragment>
    )
}

class SimpleDialog extends  React.Component<any,any>{


  constructor(props:any)
  {
      super(props);
      this.state =
      {
        fullScreen:false
      }
      
      
      
  }

  handleClose = () => {
    this.props.onClose(this.props.selectedValue);
  };


  expandClick = () => {
   
    this.setState({fullScreen: !this.state.fullScreen})

  }

 
  
  render() {
    
    
    const { classes, onClose, selectedValue,detail,  ...other} = this.props;

    const detailFact = Array.isArray(detail)?detail[0]:detail;

    const facts = Array.isArray(detail) ? (detail.length ===1 ?
      <FactModalContent 
      expandClick ={this.expandClick}
       {...this.props} detail={detailFact}
      ></FactModalContent>
      :
      <TabbedFacts  expandClick ={this.expandClick}
        {...this.props}></TabbedFacts>
    )
    :<FactModalContent 
      expandClick ={this.expandClick}
      {...this.props}
      ></FactModalContent>
   
    
    return (
        <Dialog open={true} onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other}  fullScreen={this.state.fullScreen}>

          {this.props.title && <DialogTitle id="simple-dialog-title">{this.props.title}</DialogTitle>}

          <Paper  elevation={1} style={{ padding: 2, margin: 2, backgroundColor: "#fafafa" }}>
          {facts}
          </Paper>
          
      </Dialog>
    );
  }
}


class TabbedFacts extends  React.Component<any,any>{
  constructor(props:any)
  {
    super(props);
    this.state = {value:0};
  }

  handleChange = (event:React.ChangeEvent<{}>,v:number) => {
    this.setState({value:v})
  }

  render = () =>
    {
     const tabHeadElements = this.props.detail.map( (f:ResearchNode) => <Tab key={f.id} label={f.name + " " + f.type}></Tab>)
     const tabHeading =    <AppBar position="static"><Tabs variant="scrollable"  indicatorColor="primary" scrollButtons="on" 
      value={this.state.value} onChange={this.handleChange}>{tabHeadElements}</Tabs></AppBar>

     const tabContents = this.props.detail.map( (f:ResearchNode,i:number) => this.state.value===i &&<FactModalContent key={f.id}
      expandClick ={this.props.expandClick}
       {...this.props}  detail={f}
      ></FactModalContent>)
      return <Fragment>{tabHeading}{tabContents}</Fragment>
    }
}



//  No button you handle close open
export const DetailModalPanel= withStyles(styles)(SimpleDialog);

export const SimpleDialogWrapped = withStyles(styles)(SimpleDialog);

class DetailModal extends  React.Component<any,any>{

    constructor(props:any)
    {
        super(props);
        this.state = {
          open: false

        };
    }


  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({  open: false });
  };

  render() {
    const detail = this.props.detail;

    return (
      <div>
        <Button variant="outlined" color="primary" onClick={this.handleClickOpen}>
         Detail
        </Button>
        <SimpleDialogWrapped

          detail = {detail}
          open={this.state.open}
          onClose={this.handleClose}
        />
      </div>
    );
  }
}


export default DetailModal;
