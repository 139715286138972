import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import {Button, DialogTitle,DialogActions,Dialog, DialogContent} from '@material-ui/core';





const styles = {
 
  
};

class SimpleDialog extends React.Component<any,any> {
  handleClose = () => {
    this.props.onClose(false);
  };

  handleConfirm = () => {
    this.props.onClose(true);
  };

  render() {
    const { title,classes,description,...other } = this.props;
    
    return (
      <Dialog  open ={true} onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other}>
        <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
        <DialogContent>  
          {description}        
        </DialogContent>
        <DialogActions>
        <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleConfirm} color="primary">
              Confirm
            </Button>
          </DialogActions>
      </Dialog>
    );
  }
}



export const ConfirmDialog = withStyles(styles)(SimpleDialog);





class SimpleDialogDemo extends React.Component<any,any> {
  state = {
    open: false,
  
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({  open: false });
  };

  render() {
    return (
      <div>

        <Button variant="outlined" color="primary" onClick={this.handleClickOpen}>
          Open simple dialog
        </Button>
        <ConfirmDialog
          open={this.state.open}
          onClose={this.handleClose}
        />
      </div>
    );
  }
}

export default ConfirmDialog;
