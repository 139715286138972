import * as React from 'react'
import { Paper } from "@material-ui/core";

import { Image,Page, Text, View, Document, StyleSheet, Link} from '@react-pdf/renderer';
import { ResearchItem ,ResearchNode,SourceType} from '../model/ResearchNode';
import {ToDo,ToDoFormData} from "../todo/ToDo"
import {Note} from "../note/Note"
import ResearchIcon from "../icons/png/research.png"
import styled from '@react-pdf/styled-components';
import PDFViewFactHeader from "./PDFViewFactHeader"

import{FactDetailBox,FactDetailItem ,FactNoteHeading,FactEnding,FactNote} from "./PDFViewStyles"
import { scrollIntoView } from 'react-select/lib/utils';

const Fragment = React.Fragment;


        
class PDFViewFact extends React.Component <any,any>{

    
    constructor(props:any)
    {
      super(props)
     
  
    }  
  
  
    render() {
     
      const {fact} = this.props;  

      const factDetail = Object.keys(fact.research).sort((a,b) => {
        return (fact.research[a].order - fact.research[b].order)});
      
      return (
  
      
      <View  wrap={false} >
         
         
        <PDFViewFactHeader fact={fact}/>

        <View style={styles.row}  >
        {factDetail.map((key,idx) => {return (
         <Text style={styles.cell}   key={idx} > {fact.research[key].title + "  -  " + (key=="sourceScrape" ? "" : ( fact.research[key].value || ""))} </Text>)
        }
        )}
        </View>

        
        <FactNoteHeading >Notes</FactNoteHeading >
        <FactNote>{fact.note}</FactNote>
         

        <FactEnding/>
          
       </View>
         
                   

      )
    }
  }
  
  
const styles = {

 row: {
   display: "flex",
   flexDirection: "row",
   justifyContent: "space-around",
   alignContent: "stretch",
   flexWrap: "wrap",
   alignItems: "stretch",
   flexGrow: 0,
   flexShrink: 0,
   flexBasis: 35,
   marginLeft: 20,
   marginRight: 20
 },
 cell: {

   flexGrow: 1,
   flexShrink: 1,
   width:200,
   flexBasis: "auto",
   alignSelf: "stretch",
   textAlign: "left",
   fontFamily: "Times-Roman",
   fontSize:10
   
 }
};

  export default PDFViewFact
