import React, { Component, ComponentType } from 'react';
import { withFirebase } from '../firebase';
import { AppBar, Toolbar, Typography, Paper ,TextField, Divider ,Button } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles'
import {GlobalStyles} from "../Styles"



const localstyles = {
	loginFormFields: {
     
		margin: "10px",
        padding: "10px",
        
  
	}
}

const styles = {...GlobalStyles,...localstyles}

const INITIAL_STATE = {
    username: '',
    email: '',
    passwordOne: '',
    passwordTwo: '',
    error: null,
  };



const SignUpPage = (props:any) => (
 
        <Paper className={props.classes.view}>
            <h1>Login or Register as a new User</h1>
            <SignUpForm {...props}/>    
        </Paper>
        );   

   


class SignUpFormBase extends Component<any,any> {
  constructor(props:any) {
    super(props);
    this.state = {...INITIAL_STATE}
  }

  onRegister = (event:any) => {
    const { username, email, passwordOne } = this.state;

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then((authUser:any) => {
        // Create a user in your Firebase realtime database
        return this.props.firebase
          .user(authUser.user.uid)
          .set({
            username,
            email,
            created:new Date().valueOf()
          },
          function(error:any) {
            if (error) {
              // The write failed...
            } else {
              // Data saved successfully!
            }
        });
      })       
      .then((authUser:any) => {
        this.setState({ ...INITIAL_STATE });
        
      })
      .catch((error:any) => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onLogin = (event:any) => {
    const { username, email, passwordOne } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, passwordOne)
     
      .then((authUser:any) => {
        this.setState({ ...INITIAL_STATE });
        
      })
      .catch((error:any) => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = (event:any) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
        username,
        email,
        passwordOne,
        passwordTwo,
        error,
      } = this.state;
  
      const isInvalidRegister =
        passwordOne !== passwordTwo ||
        passwordOne === '' ||
        email === '' ||
        username === '';

        const isInvalidLogin =
      
        passwordOne === '' ||
        email === '' ;
      

    return (
        <Paper className={this.props.classes.loginFormFields}>
     
        <TextField className={this.props.classes.loginFormFields}

          name="email"
          value={email}
          onChange={this.onChange}
          type="text"
          placeholder="Email Address"
        />
        <TextField className={this.props.classes.loginFormFields}
          name="passwordOne"
          value={passwordOne}
          onChange={this.onChange}
          type="password"
          placeholder="Password"
        />
        {true && <Button  className={this.props.classes.loginFormFields} name = "Login" onClick={this.onLogin}>Login</Button>}
          <Divider />
         <TextField className={this.props.classes.loginFormFields}
          name="username"
          value={username}
          onChange={this.onChange}
          type="text"
          placeholder="Full Name"
        />
        <TextField className={this.props.classes.loginFormFields}
          name="passwordTwo"
          value={passwordTwo}
          onChange={this.onChange}
          type="password"
          placeholder="Confirm Password"
        />
        {!isInvalidRegister && <Button  className={this.props.classes.loginFormFields} name = "Register" onClick={this.onRegister}>Register</Button>
        }

        {error && <p>{error.message}</p>}
  
        </Paper>
    );
  }
}


const SignUpForm = withFirebase(SignUpFormBase)


export default withStyles(styles)(SignUpPage);
export { SignUpForm};