import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {IconButton,Button , Dialog ,DialogActions,DialogTitle,DialogContent,DialogContentText, Paper,Typography, Checkbox,FormControl,InputLabel,Select,Menu,MenuItem} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import {HostURL} from '../model/Options'
import Clipboard from 'clipboard-copy'
import ExternalURLLink from '../utility/ExternalURLLink'

 
 
const styles = {
}


//TODO  the format of the URL Parameters needs to be here plus encryption

const ShareLinkText = () =>  <Typography>Copy the Link above and email to the person you wish to share a copy of this Research with.  
  They will need to be a registered user of <ExternalURLLink url={HostURL} title={"MMTWP Researcher"}></ExternalURLLink>, each time the link is clicked 
  it will make a copy of the Research (as it is when they execute the link) in their own private research folder. Your copy will not be changed </Typography>;

class ShareLinkModal extends  React.Component<any,any>{


    constructor(props:any)
    {
        super(props);
  

      
        this.state = {
 
        }
    }
  
    handleClose = () => {
      this.props.onClose(true);
    };
  
    handleCopy = () => {
      Clipboard(this.props.shareLink).then(
      
      this.props.onClose(true)
      )
    };

  
    
  
    render() {
     
      const { classes, onClose, selectedValue,  ...other} = this.props;
 
      
      return (
        <Dialog open={true} onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other}>
  
          <DialogTitle id="draggable-dialog-title" >
          {this.props.title|| "Share Research Link"}
          </DialogTitle  >
            <DialogContent>
              <DialogContentText>
              <Paper elevation={2}  style={{ margin: 16, padding: 16 }}>
                {this.props.shareLink}
              </Paper>
              <ShareLinkText/>
              </DialogContentText>
            </DialogContent>
            <DialogActions>   
              <IconButton color="inherit" onClick={this.handleCopy} aria-label="Close">
                    Copy to Clipboard
              </IconButton>
               <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                    <CloseIcon />
                </IconButton>
            </DialogActions>
        </Dialog>
      );
    }
  }
    
  
  export default withStyles(styles)(ShareLinkModal);
