import React from 'react';
import PropTypes from 'prop-types';
import {withStyles } from '@material-ui/core/styles';
import {InputAdornment,Button , TextField, FormControl,InputLabel,Select,MenuItem} from '@material-ui/core'
import {Search} from '@material-ui/icons'

import {ResearchType,DateType,ResearchNode,ResearchItem } from '../model/ResearchNode';
import FileDialog from '../filesaveload/LoadModalView'
import {HostURL} from '../model/Options'
import Firebase,{withFirebase } from '../firebase';


//TODO Need to decide on styles management
const styles = {
  factLabel: {
    width:"200px"
  },

};


/**
 * Generate JSX Array for Material UI Dropdown 
 */
const DropDownFromObject = (o:Object,n:string) =>{return (Object.keys(o).map((key,idx) => (<MenuItem name={n} key ={idx} value={key}>{key}</MenuItem>)))}



interface Props{
  firebase : Firebase;
  onSubmit : Function;
  internal ?:boolean;
  classes ?: any;
  buttonText? : string;
  fact ? : ResearchNode;
}


/**
 * Standard Component for Fact Form, Display, Edit and Create
 * @param {FireBaseContext} firebase context
 * @param {Function} onSubmit called when the submit button is press and passed form data 
 * @param {booleam} internal  optional, default is no used to flag internal (source cam be modified ) facts
 * @param {class}  optional wrapper for style
 * @returns 
 */
class FactForm extends  React.Component<Props,any>{


  constructor(props:Props)
  {
      super(props);
      this.localFact = props.fact || new ResearchNode("","");
      if (!props.fact)
      {
        this.localFact.research = {description:(new ResearchItem("Description","",0))};
 
      }

  
      this.state =
      {
        linkPicker:false,
        updateForm:false
      }
 
  }

  static propTypes:any = {
    myProp: PropTypes.string
  }

  public localFact : ResearchNode;

  
  onSubmit = (event: React.MouseEvent<HTMLElement, MouseEvent>) =>
  {
    this.props.onSubmit(this.localFact)
  }

  customInputHandler = (event:any, child?: any) => {

    let name:keyof ResearchNode  = child ? child.props.name :event["currentTarget"].name
    let value:string|boolean|number = child ? child.props.value :event["currentTarget"].value

    
    //{(this.localFact as any)[name] =value}
    // const key: (keyof ISomeObject) = 'secondKey';
    if ((this.props.internal) && (name as string === "description"))
    {
      this.localFact.research.description.value = value;
    }
    else if ((this.props.internal) && (name as string === "source"))
    {
      this.localFact.source.title = "Link";
      this.localFact.source.value = value as string;
    }
    else {
    this.localFact[name] =value;
    }

    if (name === "type")
    {
      ResearchNode.setType(this.localFact,value as string);
      ResearchNode.setImage(this.localFact)
    }
    this.setState({updateForm:true})

  }


  
 linkSearchClick = () =>
 {
  this.setState({linkPicker:true})
 }

 linkPickerClose = () =>
 {
  this.setState({linkPicker:false})
 }

 linkPicked = (f:any) =>
 //TODO interface definition for the Material Table Row Data
 {
 
  var userId = this.props.firebase.auth.currentUser ? this.props.firebase.auth.currentUser.uid: "";
  this.localFact.name= f.name;
  this.localFact.year = f.year;
  this.localFact.research.description.value = `${(f.project?(f.project+" "):"")}${f.name} ${(f.version?("Version: " +f.version):"")}
Date Saved ${new Date(f.lastsaved).toLocaleString()} Number of Facts ${f.numberOfFacts}`

  this.localFact.source.value = HostURL + '?user=' +userId +  '&research=' + f.fileId;
  this.localFact.source.type = "URL";
  this.setState({linkPicker:false})
 }


 //TODO Maybe refactor at least TextField to be a new component

  render()
  {
  const classes = this.props.classes;
  const isInternal = (this.localFact.sourceScrape && this.localFact.sourceScrape.type === "Internal") ||
    (this.props.internal == true)

  return  (
  
        <form>


          {this.state.linkPicker &&   <FileDialog open={this.state.linkPicker}  handleClose={this.linkPickerClose}   
                onFileLoad={this.linkPicked} onRowSelected={this.linkPicked} 
                small={true} title={"List of Research Projects that can be linked"}> </FileDialog>}

          <FormControl required    margin={'normal'} fullWidth={true}>
              <InputLabel className={classes.factLabel}>Fact Type</InputLabel >
              <Select value={this.localFact.type||""} name="type" onChange={this.customInputHandler} >
              {DropDownFromObject (ResearchType,"type")}
              </Select>
          </FormControl>
          


          <FormControl required  className={classes.factLabel}  margin={'normal'}>
          <InputLabel className={classes.factLabel}>Date Type</InputLabel>
          <Select value={this.localFact.dateType||DateType.Circa} name="dateType" id="dateType" onChange={this.customInputHandler} >
            {DropDownFromObject (DateType,"dateType")}
          </Select>
          </FormControl>


          <TextField label="Year"  name="year"  value={this.localFact.year||""} data-validators="isRequired" fullWidth = {false} margin={'normal'}
          onChange={this.customInputHandler} 
          />


          <TextField label="Name" type="text" name="name"  value={this.localFact.name||""} onChange={this.customInputHandler} 
            data-validators="isRequired" fullWidth = {true} margin={'normal'}
          />
          <TextField label="Location"  name="location"  value={this.localFact.location||""} onChange={this.customInputHandler} 
            fullWidth = {true} margin={'normal'}
          />

          { (isInternal  ) &&
            <TextField label="Description"  multiline fullWidth = {true}  rows="4" type="text" name="description" 
              value={this.localFact.research.description.value||""}  onChange={this.customInputHandler}                    
            /> 
          }

          { (isInternal  ) &&
          <TextField label="Source" type="text" name="source"  multiline fullWidth = {true}  rows="1" 
            value={this.localFact.source.value||""} onChange={this.customInputHandler} 
                            InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <Search onClick = {this.linkSearchClick}/>
              </InputAdornment>
            )
          }}

          />
          } 

          <TextField label="Confidence" type="number" name="confidence"  fullWidth = {true}  rows="1" 
            value={this.localFact.confidence||"100"} onChange={this.customInputHandler} 
            /> 

         
          <TextField label="Notes"  name="note"   multiline  fullWidth = {true}  rows="5"
            value={this.localFact.note||""} onChange={this.customInputHandler} 
          /> 
          
          <div style={{justifyContent: 'center'}}>
          <Button variant="raised" fullWidth = {true} style={{marginTop:"10px"}}
             onClick = {this.onSubmit}>{this.props.buttonText || "Add Fact"}</Button>
          </div>
      
        </form>
      )
    }
  }



  const formStyled = withStyles(styles)(FactForm);

  export default withFirebase(formStyled);