import React from "react";
import { List, Paper, Grid } from "@material-ui/core";

import TodoListItem from "./ToDoListItem"
import { defaultProps } from "react-select/lib/Creatable";
import ToDo from "./ToDo";

const ToDoList = (props:any) => (
  <>
    {props.items.length > 0 && (
      <Paper style={{ margin: 16 }}>
        <List style={{ overflow: "auto" }}>
          {props.items.map((todo:ToDo,idx:number) => (
            <TodoListItem
              {...todo}
              selected = {props.selected===todo.id}
              research = {props.research}
              key={todo.id}
              divider={idx !== props.items.length - 1}
              onButtonClick={() => props.onItemRemove(idx)}
              onCheckBoxToggle={() => props.onItemCheck(idx)}
            />
          ))}
        </List>
      </Paper>
    )}
  </>
);

export default ToDoList;