
enum MessageType 
{
    success = 'success',
    error = 'error',
    info = 'info',
    warning = 'warning'
   
}

export class StatusMessage
{
    status:boolean = false;
    message:string = "";
    id:string = "00";
    type : MessageType = MessageType.info;
    icon?:any;

    

}

export default StatusMessage; 


