import styled from '@react-pdf/styled-components';

export const Body = styled.Page`
padding-top: 35px;
padding-bottom: 65px;
padding-right: 35px;
padding-left: 35px;
`;

export const Heading = styled.Text`

font-size: 22px;
font-family: 'Helvetica'
padding:10px;
`;

export const HeadingText = styled.Text`

font-size: 22px;
font-family: 'Helvetica'

text-align: center;
color: grey;
padding-left: 35px;
margin-left: 35px;
max-height : 25pt;
`;

export  const Subtitle = styled.Text`
margin: 12px;
font-size: 18px;
font-family: 'Helvetica';
`;

export const Paragraph = styled.Text`
margin: 12px;
font-size: 14px;
text-align: left;
font-family: 'Times-Roman';

`;


export const ParagraphRight = styled.Text`
margin: 12px;
font-size: 8px;
text-align: left;
font-family: 'Times-Roman';

`;

export const SectionHeading = styled.Text`
margin: 14px;
font-size: 24px;
font-weight: bold;

font-family: 'Times-Roman';
text-align: center;
border-bottom-width : 2px;
border-bottom-color : #a9a9a9;
margin-bottom:10px;
padding-bottom:10px;
`;

export const FactHeading = styled.Text`

margin: 14px;
font-size: 16px;
font-weight: bold;
font-family: 'Times-Roman';
text-align: left;
margin-bottom:5px;
padding-bottom:5px;
`

export const FactLink = styled.Text`
margin-left: 20px;
margin-bottom: 5px;  
font-size: 14px;
text-align: left;
font-family: 'Times-Roman';
padding:0px

`;


export const FactDetailBox = styled.View`

display: flex;
flex-wrap: wrap;
width:100%;

      `;

export const FactDetailItem = styled.Text`

flex-grow :1;
flex-shrink:1;
flex-basis:100px;
border:solid;




`;





export const FactNote = styled.Text`
margin-left: 25px;
margin-bottom: 2px;  
font-size: 10px;
text-align: left;
font-family: 'Times-Roman';
padding:0px

`;

export const FactDetailHeading = styled.Text`
margin-left: 20px;
margin-right: 20px;
font-size: 14px;
text-align: left;
font-family: 'Times-Roman';
padding:0px
border-bottom-width : 1px;
border-bottom-color : #a9a9a9;
margin-bottom:10px;
  
`;

export const FactNoteHeading = styled.Text`
margin-left: 20px;
margin-right: 20px;
font-size: 14px;
text-align: left;
font-family: 'Times-Roman';
padding:0px
border-bottom-width : 1px;
border-bottom-color : #a9a9a9;
margin-top:10px;
  
`;

export const FactEnding = styled.Text`

margin: 12px;
font-size: 12px;
font-weight: bold;
font-family: 'Times-Roman';
text-align: left;
border-bottom-width : 1px;
border-bottom-color : #a9a9a9;
margin-bottom:10px;
padding-bottom:10px;
`

export const Logo = styled.Image`

      maxWidth:25pt,
      maxHeight:25pt`;

