import React, { SyntheticEvent, ChangeEvent } from "react";
import { TextField, Paper, Button, Grid } from "@material-ui/core";
import {ToDoFormData}  from "./ToDo"
import ResearchPicker from  "../utility/ResearchPicker"

class ToDoForm extends React.Component<any,any>{

  toDoFormData  : ToDoFormData
  constructor(props:any)

  {
    super(props)
    this.toDoFormData   = props.toDoFormData  ? props.toDoFormData  :new ToDoFormData()
    this.state=
    {
      value:this.toDoFormData.note,
      toDoFormData:this.toDoFormData
    }
  }

  onButtonClick = (e:any)=>
  {
    if (e.currentTarget.name == "Clear")
    {
      this.props.ClearToDoForm()
    }
    else{
    this.props.onButtonClick(this.toDoFormData)
    }
  }

  onChange = (e:ChangeEvent<HTMLInputElement>) =>
  {
    
    if (e.currentTarget.name == "noteField")
    {
       this.toDoFormData.note =  e.currentTarget.value;
       this.setState({value:this.toDoFormData.note});       
    }
    

  }

  nodeSelected = (v:string) =>
  {
    debugger;
    this.toDoFormData.nodeId = v;
    this.setState({value:this.toDoFormData.note});
  }

  render()
  {
   this.toDoFormData = this.props.toDoFormData;

    return (
  <Paper style={{ margin: 16, padding: 16 }}>
    <Grid container>
      <Grid xs={10} md={11} item style={{ paddingRight: 16 }}>
        <TextField
          name ={'noteField'}
          placeholder="Add Todo here"
          fullWidth = {true}
          onChange = {this.onChange}
          value= {this.toDoFormData.note}
        />
        <ResearchPicker
        research={this.props.research}
        onChange = {this.nodeSelected}
        value  ={this.toDoFormData.nodeId}
        >
        </ResearchPicker>
      </Grid>
      <Grid xs={2} md={1} item>
      {this.toDoFormData.note.length >0 && this.toDoFormData.id === "" &&  <Button fullWidth  onClick={this.onButtonClick}>Add</Button>}
      {this.toDoFormData.id != "" && <Button fullWidth  onClick={this.onButtonClick}>Modify</Button>}
      {(this.toDoFormData.id != "" || this.toDoFormData.note.length >0)&& <Button fullWidth  name = "Clear" onClick={this.onButtonClick}>Clear</Button>}
        
      </Grid>
    </Grid>
  </Paper>

)}
    }

export default ToDoForm;