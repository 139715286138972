import React, { Component } from 'react';
import Konva from 'konva';
import ResearchIcon from './icons/png/research.png'

import { render } from 'react-dom';
import { Stage, Layer, Rect, Text ,Image,Group, Line} from 'react-konva';

import {ResearchNode} from '../model/ResearchNode'

import useImage from 'use-image'
import {Coordinates,Arrow} from "../model/DisplayNode"
import {PageA4P,PageA5L} from "../page/Page"

import {Toolbar,IconButton,Typography,withStyles,Tooltip} from "@material-ui/core"
import {ZoomIn,ZoomOut,CropPortrait,CropLandscape,ArrowUpward,ArrowDownward,InfoOutlined,SettingsEthernetOutlined,
        UnfoldMore,UnfoldLess} from "@material-ui/icons"

import LeftClickToggle from './LeftClickToggle';
import {Options,YearOrderingType} from '../model/Options';

const styles = {
    // This group of buttons will be aligned to the right
    rightToolbar: {
      marginLeft: 'auto',
      marginRight: -12,
    },
    menuButton: {
      marginRight: 16,
      marginLeft: -12,
    },
    toolBarMenu :
    {
        marginLeft:10
    }
  };


class GraphicToolBar extends React.Component<any,any>{

    constructor (props:any)
    {
        super(props);

        this.state ={
            leftClickToggle:this.props.leftClickToggle
        }
    }

    leftClickToggle = (e:any) =>
    {
        this.props.onClickLeftClickToggle(e.currentTarget.name);
        this.setState({leftClickToggle: e.currentTarget.name});
    }

    render = () =>

    {
        const {classes} =  this.props;
        const options:Options = this.props.options;
        const leftClickToggle = this.state.leftClickToggle;
        return <Toolbar>
 
            <IconButton  disabled>
                <CropPortrait></CropPortrait>
            </IconButton>
            <Typography>A4 Portrait</Typography>
            <IconButton disabled>
                <CropLandscape>
                </CropLandscape> 
            </IconButton>
            <Tooltip title ="Click to decrease the starting decade">
                <IconButton onClick = {this.props.onClickYearUp}>
                    <ArrowDownward></ArrowDownward>
                </IconButton>
            </Tooltip>
            <Typography>{this.props.options.startingHalfDecade}</Typography>
            <Tooltip title ="Click to increase the starting decade">
                <IconButton onClick = {this.props.onClickYearDown}>
                <ArrowUpward></ArrowUpward>
                </IconButton>  
            </Tooltip>
            <Tooltip title ="Click to Decrease the decades displayed on the Page">            
                <IconButton onClick = {this.props.onClickZoomIn}>
                    <ZoomIn></ZoomIn>
                </IconButton>
            </Tooltip>
            <Typography >{(Math.round(options.zoom *100))+"%"}</Typography>
            <Typography></Typography>
            <Tooltip title ="Click to Increase the decades displayed on the Page">
                <IconButton onClick = {this.props.onClickZoomOut}>
                    <ZoomOut></ZoomOut>
                </IconButton>
            </Tooltip>
            <Tooltip title ="Click to arrange the facts in Strict Year Order">          
            <IconButton onClick = {this.props.onClickYearOrderStrict}  >
                <UnfoldMore/>
            </IconButton>
            </Tooltip>     
            
            <Typography >{options.yearOrdering === 1?"Loose":"Strict"}</Typography>
            <Tooltip title ="Click to arrange the facts in Loose Year Order">          
            <IconButton onClick = {this.props.onClickYearOrderLoose} >
                <UnfoldLess/>
            </IconButton>
            </Tooltip>     

            <Typography className= {classes.toolBarMenu} variant="title" color="inherit">{this.props.name||""}</Typography>
            <section className={classes.rightToolbar}>
            <Tooltip title ="Click to make Show Fact Detail the Left Click Option">
            <IconButton onClick = {this.leftClickToggle} name={LeftClickToggle.Detail}  disabled = {leftClickToggle===LeftClickToggle.Detail}>
                <InfoOutlined/>
            </IconButton>
            </Tooltip>
            <Tooltip title ="Click to make draw Arrows the Left Click Option">          
            <IconButton onClick = {this.leftClickToggle} name={LeftClickToggle.Join}  disabled = {leftClickToggle===LeftClickToggle.Join}>
                <SettingsEthernetOutlined/>
            </IconButton>
            </Tooltip>

        


            </section>
        </Toolbar>

    }
}

export default withStyles(styles)(GraphicToolBar);


